<div id="schedules"
     class="content">
  <div class="content-header">
    <h5 class="mb-0">Horarios</h5>
    <small class="text-muted">Creación de horarios de las estaciones seleccionadas y asignación de choferes y
      vehículos.</small>
  </div>

  <button class="btn btn-primary btn-sm"
          (click)="addSchedule()">Agregar horario
  </button>

  <div class="card mt-2 cursor-pointer"
       *ngIf="routeModal.schedules.length">
    <div class="d-flex flex-column p-2 position-relative"
         (click)="editSchedule(index)"
         *ngFor="let currentSchedule of routeModal.schedules; index as index; last as last"
         [ngClass]="{'border-bottom': !last}">

      <span class="font-weight-bolder">{{currentSchedule.name}}</span>

      <div class="py-2 d-flex flex-column">
        <span class="font-weight-bolder font-small-2">Chofer: {{currentSchedule?.driver?.name}} {{currentSchedule?.driver?.lastName}}</span>
        <span class="font-weight-bolder font-small-2">Vehículo: {{currentSchedule?.vehicle?.carRegistration}}</span>
      </div>

      <div class="d-flex flex-column">
        <div class="d-flex pb-1 w-100 flex-row align-items-center justify-content-between"
             *ngFor="let stationSchedule of currentSchedule.schedule; index as index">
          <div class="d-flex flex-row">
            <span class="font-small-3 font-weight-bold mr-1">{{index + 1}}.</span>
            <span class="font-small-3">{{stationSchedule.station.name}}</span>
          </div>
          <div class="d-flex flex-column ml-1 justify-content-center schedules-box">
            <span class="font-weight-bold font-small-2">Horarios</span>
            <span class="font-small-1"
                  *ngFor="let schedule of stationSchedule.hours; index as index">{{schedule}}</span>
          </div>
        </div>
      </div>
      <i class="fa fa-times close-icon"
         (click)="deleteSchedule(currentSchedule, index); $event.stopPropagation()"></i>
    </div>
  </div>
  <div class="d-flex justify-content-between mt-3">
    <button class="btn btn-outline-secondary btn-prev"
            (click)="routeModal.previousWizard()"
            rippleEffect>
      <i data-feather="arrow-left"
         class="align-middle mr-sm-25 mr-0"></i>
      <span class="align-middle d-sm-inline-block d-none">Anterior</span>
    </button>

    <button class="btn btn-primary btn-next"
            [disabled]="!routeModal.schedules.length"
            (click)="routeModal.verticalWizardNext()"
            rippleEffect>
      <span class="align-middle d-sm-inline-block d-none">Siguiente</span>
      <i data-feather="arrow-right"
         class="align-middle ml-sm-25 ml-0"></i>
    </button>
  </div>
</div>
