import {Component, OnInit} from '@angular/core';
import moment from "moment";

@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.scss']
})
export class LogComponent implements OnInit {
  log: any

  constructor() {
  }

  ngOnInit(): void {
    this.log.arriveDate = moment(this.log.arriveDate.toDate()).format('DD/MM/YYYY HH:mm')
    this.log.departDate = moment(this.log.departDate.toDate()).format('DD/MM/YYYY HH:mm')
  }

}
