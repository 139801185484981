<div class="modal-header d-flex align-items-center flex-row">
  <h4 class="modal-title pull-left align-items-center">Agregar horario a {{currentSchedule.name}}</h4>
  <button type="button" class="pull-right btn" (click)="modal.hide()">
    <i class="fa fa-times"></i>
  </button>
</div>
<div class="p-2 flex-column d-flex">
  <div class="d-flex flex-row align-items-center w-100 justify-content-between">
    <div class="d-flex flex-column w-100 mr-2">
      <label>Seleccionar horario</label>
      <input type="time"
             [(ngModel)]="hourSelected"
             class="form-control">
    </div>
    <div
      class="btn btn-outline-primary btn-sm mt-2"
      (click)="addScheduleToStation()">Agregar
    </div>
  </div>
</div>

<div class="modal-body">
  <div class="row" id="table-small" *ngIf="!!currentSchedule.hours && !!currentSchedule.hours.length">
    <div class="col-12">
      <div class="card">
        <div class="table-responsive table-striped">
          <table class="table table-sm">
            <thead>
            <tr>
              <th>#</th>
              <th>Hora</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let schedule of currentSchedule.hours; index as index">
              <td>{{index + 1}}</td>
              <td>{{schedule}}</td>
              <td>
                <button
                  type="button"
                  class="pull-right btn"
                  (click)="deleteSchedule(index)">
                  <i class="fa fa-trash"></i>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
