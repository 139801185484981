import {Component, OnInit, ViewChild} from '@angular/core';
import {
  UploadFileStorageComponent
} from "../../../shared/template-components/upload-file-storage/upload-file-storage.component";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {BsModalRef} from "ngx-bootstrap/modal";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {ToastService} from "../../../shared/services/toast.service";
import {AlertService} from "../../../shared/services/alert.service";
import {StationService} from "../../services/station.service";
import { Station } from 'app/admin/interfaces/station';

@Component({
  selector: 'app-station-modal',
  templateUrl: './station-modal.component.html',
  styleUrls: ['./station-modal.component.scss']
})
export class StationModalComponent implements OnInit {
  @ViewChild(UploadFileStorageComponent) filesStorage: UploadFileStorageComponent;
  station: Station = {key: ''} as Station;
  stationForm: FormGroup
  submitted: boolean = false;
  loading: boolean = false;
  isEdit: boolean = false;
  routeFile: string;
  isPreview: boolean = false;
  marker = null;

  constructor(public modal: BsModalRef,
              private formBuilder: FormBuilder,
              private _station: StationService,
              private afs: AngularFirestore,
              private toastr: ToastService) {
    this.createStationForm();
  }

  ngOnInit(): void {
    if (this.isEdit) {
      this.stationForm.patchValue({...this.station});
      this.marker = {
        lat: this.station.latitude,
        lng: this.station.longitude
      }
    } else {
      this.station.key = this.afs.createId();
    }

    this.routeFile = `stations/${this.station.key}/photo`;
  }

  createStationForm() {
    this.stationForm = this.formBuilder.group({
      name: [null, Validators.required],
      latitude: [0],
      longitude: [0],
      address: [null, Validators.required],
      aditionalInfo: [null],
      updatedAt: [new Date().getTime()],
      createdAt: [new Date().getTime()],
      trash: [false],
      endDate: [null],
      imageUrl: [null],
    })
  }

  get formControls() {
    return this.stationForm.controls;
  }

  async submit() {
    this.submitted = true;
    if (!this.stationForm.valid) return AlertService.toastError('Verifique sus datos');
    if (!await AlertService.confirm('Estás seguro de que deseas guardar esta estación?')) return;

    this.loading = true;

    if (this.isPreview) {
      const url = await this.filesStorage.uploadDocument();
      if (!!url) this.stationForm.patchValue({[this.filesStorage.controlName]: url});
    }

    await this._station.set(this.station.key, this.stationForm.value);
    this.toastr.showSuccess('Los datos se guardaron correctamente');
    this.loading = false;
    this.modal.hide();
  }

  assignAddress({address, latitude, longitude}: any) {
    this.stationForm.patchValue({
      ...this.stationForm.value,
      address,
      latitude,
      longitude
    })
  }
}
