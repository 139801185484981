<form action=""
      *ngIf="!!form"
      [formGroup]="form">
  <ng-content></ng-content>

  <button class="btn btn-primary float-right mb-2"
          type="button"
          (click)="emmitSubmit()">
    Guardar
  </button>
</form>
