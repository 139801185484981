<div class="p-2">
  <h3>Vehículo actual: {{vehicle.name}}</h3>

  <h4 class="mt-4">Seleccionar nuevo vehículo</h4>

  <app-datatable [rows$]="vehicles$"
                 [config]="config"
                 (rowSelected)="handleSelectVehicle($event)">
    <app-datatable-column title="Chofer">
      <ng-template let-row
                   #column>
        {{row.brand}} {{row.model}} {{row.yearModel}} - {{row.unitID}}
      </ng-template>
    </app-datatable-column>
  </app-datatable>
</div>
