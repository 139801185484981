// IMPORTS
import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreCollectionGroup,
  AngularFirestoreDocument,
  DocumentReference
} from '@angular/fire/compat/firestore';
import { first, map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { QueryFn } from '@angular/fire/compat/firestore/interfaces';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { AlertService } from './alert.service';

type  DocPredicate<T> = string | AngularFirestoreDocument<T>;
type  CollectionPredicate<T> = string | AngularFirestoreCollection<T>;

@Injectable({
  providedIn: 'root'
})
export class FirebaseDataService {

  constructor(private db: AngularFirestore,
              private storage: AngularFireStorage) {
  }

  col<T>(ref: CollectionPredicate<T>, queryFn?: QueryFn): AngularFirestoreCollection<T> {
    return typeof ref === 'string' ? this.db.collection<T>(ref, queryFn) : ref;
  }

  colGroup<T>(ref: string, queryFn?: any): AngularFirestoreCollectionGroup<T> {
    return this.db.collectionGroup<T>(ref, queryFn);
  }

  doc<T>(ref: DocPredicate<T>): AngularFirestoreDocument<T> {
    return typeof ref === 'string' ? this.db.doc<T>(ref) : ref;
  }

  doc$<T>(ref: DocPredicate<T>): Observable<T> {
    return this.doc(ref).snapshotChanges().pipe(map(doc => {
      const data = doc.payload.data() as T;
      if (data == null) {
        return data;
      }
      data['key'] = doc.payload.id;
      return data;
    }));
  }

  col$<T>(ref: CollectionPredicate<T>, queryFn?: QueryFn): Observable<T[]> {
    return this.col(ref, queryFn).snapshotChanges().pipe(
      map(docs => docs.map(a => a.payload.doc.data()) as T[])
    );
  }

  colWithIds$<T>(ref: CollectionPredicate<T>, queryFn?: QueryFn): Observable<T[]> {
    return this.col(ref, queryFn).snapshotChanges().pipe(
      map(docs => docs.map(a => {
        const data = a.payload.doc.data();
        if (data == null) {
          return data;
        }
        data['key'] = a.payload.doc.id;
        return data;
      }) as T[])
    );
  }

  colStateWithIds$<T>(ref: CollectionPredicate<T>, states: any[], queryFn?: QueryFn): Observable<T[]> {
    return this.col(ref, queryFn).stateChanges(states).pipe(
      map(docs => docs.map(a => {
        const data = a.payload.doc.data();
        if (data == null) {
          return data;
        }
        data['key'] = a.payload.doc.id;
        return data;
      }) as T[])
    );
  }

  colGroupWithIds$<T>(ref: string, queryFn?: QueryFn): Observable<T[]> {
    return this.colGroup(ref, queryFn)
      .snapshotChanges()
      .pipe(
        map(docs => docs.map(a => {
          const data = a.payload.doc.data();
          data['key'] = a.payload.doc.id;
          return data;
        }) as T[])
      );
  }

  docWithId$<T>(ref: DocPredicate<T>): Observable<T> {
    return this.doc(ref).snapshotChanges().pipe(map(doc => {
      const data = doc.payload.data() as T;
      if (data == null) {
        return data;
      }
      data['key'] = doc.payload.id;
      return data;
    }));
  }

  getReference(url: string): DocumentReference {
    return this.db.doc(url).ref;
  }

  createID() {
    return this.db.createId();
  }

  async uploadFile(file, route: string) {
    const uploadRef = this.getStorageRefFile(route);
    await uploadRef.put(file);
    const url = await uploadRef.getDownloadURL().pipe(take(1)).toPromise();
    this.uploadFileStorage(file, route);

    return url;
  }

  private getStorageRefFile(route) {
    return this.storage.ref(`${route}`);
  }

  private uploadFileStorage(data, route: string) {
    return this.storage.upload(`${route}`, data);
  }

  update(route: string, data: any) {
    for (let property in data) {
      if (data[property] === undefined) {
        console.error(`La propiedad "${property}" llegó undefined al intentar actualizar el documento "${route}"`);
        AlertService.error(`La propiedad "${property}" llegó undefined al intentar actualizar el documento "${route}"`);
      }
    }
    this.db.doc(route).update({
      ...data,
      updatedAt: new Date().getTime()
    });
  }

  add(route: string, data: any) {
    this.db.collection(route).add({
      ...data,
      trash: false,
      createdAt: new Date().getTime()
    });
  }

  set(route: string, data: any) {
    this.db.doc(route).set({
      ...data,
      trash: false,
      createdAt: new Date().getTime()
    });
  }


  async populate(items: any[], fields: string[], subFields: string[] = []) {
    return await Promise.all(items.map(async item => {
      for (let field of fields) {
        if (item[field] && item[field].path) {
          try {
            item[field] = await this.docWithId$(item[field].path).pipe(first()).toPromise();

            for (let subField of subFields) {
              if (item[field][subField]) {
                item[field][subField] = await this.docWithId$(item[field][subField].path).pipe(first()).toPromise();
              }
            }
          } catch (e) {
            console.error(`Hubo un error al obtener el documento del campo ${field.toUpperCase()}`, e);
          }
        }
      }
      return item;
    }));
  }

  async populateArrayField(items: any[], fields: string[]) {
    return await Promise.all(items.map(async item => {
      for (let field of fields) {
        if (item[field]) {
          try {
            item[field] = await Promise.all(item[field].map(async (itemField: any) => {
              return await this.docWithId$(itemField.path).pipe(first()).toPromise();
            }))
            item[field] = item[field].filter(item => !!item);
          } catch (e) {
            console.error(`Hubo un error al obtener el documento del campo ${field.toUpperCase()}`, e);
          }
        }
      }
      return item;
    }));
  }
}
