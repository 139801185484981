import {
  Component,
  ContentChildren, ElementRef, EventEmitter,
  Input,
  OnInit, Output,
  QueryList, ViewChild,
} from '@angular/core';
import {Observable} from 'rxjs';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {DataTableConfig} from '../../interfaces/data-table-config';
import {DataTableColumnComponent} from '../data-table-column/data-table-column.component';
import {ExportToCsv} from 'export-to-csv';
import {DataTableOptionComponent} from '../data-table-option/data-table-option.component';
import {map} from 'rxjs/operators';
import {PrintService} from '../../services/print.service';

@Component({
  selector: 'app-datatable',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.css']
})
export class DataTableComponent implements OnInit {
  @ContentChildren(DataTableColumnComponent) columns: QueryList<DataTableColumnComponent>;
  @ContentChildren(DataTableOptionComponent) options: QueryList<DataTableOptionComponent>;
  @Input() rows$: Observable<any>;
  @Input() config: DataTableConfig;
  @Input() printSmall: boolean = false;
  @Output() rowSelected: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('componentID') componentID: ElementRef;

  public perfectScrollbarConfig: PerfectScrollbarConfigInterface = {suppressScrollY: true};
  search: string;
  isPrinting: boolean = false;
  orderType = 'asc';

  constructor() {
  }

  ngOnInit(): void {
    this.rows$.pipe(
      map(rows => rows.map(row => {
        row['isHover'] = false;
        return row;
      })));
  }

  selectRow(isClickable: boolean, row) {
    if (isClickable) this.rowSelected.emit(row);
  }

  printList(): void {
    this.isPrinting = true;
    setTimeout(() => {
      PrintService.print(this.componentID.nativeElement);
      this.isPrinting = false;
    }, 100);
  }

  trackByKey(index: number, item: any): number {
    return item.key;
  }

  exportToExcel(rows) {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      filename: this.config.title,
      title: this.config.title,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      headers: Object.keys(rows[0])
    };

    let csvData = [];
    for (let row of rows) {
      csvData.push(row);
    }
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(csvData);
  }
}
