import { Component, OnDestroy, OnInit } from '@angular/core';
import { AssignService } from "../../services/assign.service";
import { DocumentReference } from "@angular/fire/firestore";
import * as _ from 'lodash';
import { Subscription } from "rxjs";
import { Driver } from "../../../../interfaces/driver";
import { BsModalService } from "ngx-bootstrap/modal";
import { DriverSelectionComponent } from "../../modals/driver-selection/driver-selection.component";
import { ScheduleSelectionComponent } from "../../modals/schedule-selection/schedule-selection.component";

@Component({
  selector: 'app-driver',
  templateUrl: './driver.component.html',
  styleUrls: ['./driver.component.scss']
})
export class DriverComponent implements OnInit, OnDestroy {
  items = []
  ganttOptions = {
    columnWidth: 47,
    fixedColumnMinWidth: 100,
    fixedColumnMaxWidth: 100,
    fixedColumnGroupMinWidth: 150,
    fixedColumnGroupMaxWidth: 150,
  }
  driverSubscription: Subscription = new Subscription();
  scheduleSubscription: Subscription = new Subscription();

  constructor(public _assign: AssignService,
              private modal: BsModalService) {
  }

  ngOnInit(): void {
    this.driverSubscription = this._assign.drivers$.subscribe(drivers => {
      this.scheduleSubscription = this._assign.schedules$.subscribe(schedules => {
        this.items = drivers.map(driver => {
          const decoratedSchedules = schedules
            .map(schedule => ({
              ...schedule,
              stationHours: schedule.schedule,
              schedule: undefined
            }))
            .filter(schedule => schedule.driver)
            .filter(schedule => (schedule.driver as DocumentReference).id === driver.key)
            .map(schedule => ({
              ...schedule,
              allHours: _.flatMap(schedule.stationHours.map(station => station.hours)).sort()
            }))

          return {
            id: driver.key,
            name: driver.name,
            lastName: driver.lastName,
            isHeader: false,
            isFixed: false,
            groups: this._assign.decorateHoursGroup({ decoratedSchedules, driver })
          }
        })
      })
    })
  }

  ngOnDestroy(): void {
    this.driverSubscription.unsubscribe();
    this.scheduleSubscription.unsubscribe();
  }

  handleGroupItemClick({ item, group }) {
    this.modal.show(DriverSelectionComponent, {
      initialState: {
        driver: item,
        schedule: group.data
      }
    })
  }

  handleDriverClick(driver: Driver) {
    this.modal.show(ScheduleSelectionComponent, {
      initialState: {
        driver
      }
    })
  }
}
