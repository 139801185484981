import {Component, EventEmitter, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {BsModalService} from "ngx-bootstrap/modal";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {ToastService} from "../../../../../../../shared/services/toast.service";
import {AlertService} from "../../../../../../../shared/services/alert.service";
import {ScheduleService} from "../../../../../../services/schedule.service";
import {StationService} from "../../../../../../services/station.service";
import {RouteService} from "../../../../../../services/route.service";
import {ScheduleStationModalComponent} from "../../../../../../modals/schedule-station-modal/schedule-station-modal.component";
import {Schedule} from "../../../../../../interfaces/schedule";

@Component({
  selector: 'app-schedules-stations-modal',
  templateUrl: './schedules-stations-modal.component.html',
  styleUrls: ['./schedules-stations-modal.component.scss']
})
export class SchedulesStationsModalComponent implements OnInit {
  stations: any[] = [];
  routeKey: string;
  scheduleForm: FormGroup;
  submitted: boolean = false;
  loading: boolean = false;
  isEdit: boolean = false;
  schedules: any = {key: ''} as any;
  drivers: any = [];
  vehicles: any = [];
  public scheduleEmitter: EventEmitter<any> = new EventEmitter();

  constructor(public modal: BsModalService,
              private formBuilder: FormBuilder,
              private _schedule: ScheduleService,
              private afs: AngularFirestore,
              private toastr: ToastService,
              private _station: StationService,
              private _route: RouteService) {
    this.createScheduleForm();
  }

  ngOnInit(): any {
    if (!this.isEdit) {
      return this.schedules = {
        schedule: this.stations.map(station => ({
          station,
          schedule: []
        })),
        key: this.afs.createId()
      }
    }
    this.scheduleForm.patchValue({
      ...this.schedules,
    });
  }

  createScheduleForm() {
    this.scheduleForm = this.formBuilder.group({
      name: ['', Validators.required],
      schedule: [],
      updatedAt: [new Date().getTime()],
      createdAt: [new Date().getTime()],
      trash: [false]
    })
  }

  get formControls() {
    return this.scheduleForm.controls;
  }

  async submit() {
    this.submitted = true;

    if (!this.scheduleForm.valid) return AlertService.toastError('Verifique sus datos');
    if (!await AlertService.confirm('Estás seguro de que deseas guardar este horario?')) return;

    this.loading = true;

    this.scheduleForm.patchValue({
      ...this.scheduleForm.value,
      route: this._route.getRef(this.routeKey),
      schedule: this.schedules.schedule.map(stationSchedule => ({
        station: stationSchedule.station,
        hours: stationSchedule.hours
      }))
    });

    this.scheduleEmitter.emit( {
      ...this.scheduleForm.value,
      key: this.schedules.key
    })

    this.loading = false;

    this.modal.hide('stationsSchedules');
  }

  closeModal() {
    this.scheduleEmitter.emit(null);
    this.modal.hide('stationsSchedules');
  }

  openScheduleSelectorModal(schedule: Schedule) {
    this.modal.show(ScheduleStationModalComponent, {
      initialState: {
        currentSchedule: schedule
      }
    })
  }
}
