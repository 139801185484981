import { Component, OnInit } from '@angular/core';
import { Driver } from "../../../../interfaces/driver";
import { Schedule } from "../../../../interfaces/schedule";
import { Observable } from "rxjs";
import { DataTableConfig } from "../../../../../shared/interfaces/data-table-config";
import { AssignService } from "../../services/assign.service";
import { ScheduleService } from "../../../../services/schedule.service";
import { BsModalRef } from "ngx-bootstrap/modal";
import { map } from "rxjs/operators";
import { AlertService } from "../../../../../shared/services/alert.service";
import { Vehicle } from "../../../../interfaces/vehicle";

@Component({
  selector: 'app-vehicle-selection',
  templateUrl: './vehicle-selection.component.html',
  styleUrls: ['./vehicle-selection.component.scss']
})
export class VehicleSelectionComponent implements OnInit {
  vehicle: any;
  schedule: Schedule
  vehicles$: Observable<Vehicle[]> = new Observable<Vehicle[]>();
  config: DataTableConfig = {
    hasSearch: false,
    notFoundText: "No hay vehículos disponibles",
    title: ""
  };

  constructor(private _assign: AssignService,
              private _schedule: ScheduleService,
              private modal: BsModalRef) {
  }

  ngOnInit(): void {
    this.vehicles$ = this._assign.vehicles$
      .pipe(
        map(vehicles => vehicles.filter(vehicle => vehicle.key !== this.vehicle.id))
      );
  }

  async handleSelectVehicle(vehicle: Vehicle) {
    if (await AlertService.confirm('¿Está seguro de asignar este vehículo?')) {
      await this._schedule.updateSchedule(this.schedule.route.key, this.schedule.key, {
        vehicle: this._schedule.getReference(`vehicles/${vehicle.key}`)
      })

      AlertService.toastSuccess('Conductor asignado correctamente');
      this.modal.hide();
    }
  }

}
