<div id="driver-vehicle"
     class="content">
  <div class="content-header">
    <h5 class="mb-0">Chofer y vehículo</h5>
    <small class="text-muted">Seleccionar al chofer y el vehículo correspondiente.</small>
  </div>

  <div class="mt-1">
    <label>Chofer</label>
    <ng-select
      placeholder="Seleccionar a un chofer"
      [items]="routeModal.drivers"
      bindLabel="name"
      [formControl]="routeModal.getFormControl('driver')"></ng-select>
  </div>

  <div class="mt-1">
    <label>Vehículo</label>
    <ng-select
      placeholder="Seleccionar un vehículo"
      [items]="routeModal.vehicles"
      bindLabel="carRegistration"
      [formControl]="routeModal.getFormControl('vehicle')"></ng-select>
  </div>

  <div class="d-flex justify-content-between mt-3">
    <button class="btn btn-outline-secondary btn-prev"
            (click)="routeModal.previousWizard()"
            rippleEffect>
      <i data-feather="arrow-left"
         class="align-middle mr-sm-25 mr-0"></i>
      <span class="align-middle d-sm-inline-block d-none">Anterior</span>
    </button>

    <button class="btn btn-primary btn-next"
            [disabled]="this.routeModal.routeForm.invalid"
            (click)="routeModal.verticalWizardNext()"
            rippleEffect>
      <span class="align-middle d-sm-inline-block d-none">Siguiente</span>
      <i data-feather="arrow-right"
         class="align-middle ml-sm-25 ml-0"></i>
    </button>
  </div>
</div>
