import { Component, OnInit, ViewChild } from '@angular/core';
import { DriverService } from "../../services/driver.service";
import { take } from "rxjs/operators";
import MapTypeId = google.maps.MapTypeId;
import { MapInfoWindow, MapMarker } from "@angular/google-maps";

@Component({
  selector: 'app-realtime-map',
  templateUrl: './realtime-map.component.html',
  styleUrls: ['./realtime-map.component.scss']
})
export class RealtimeMapComponent implements OnInit {
  @ViewChild('map') map: google.maps.Map;
  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;
  options: google.maps.MapOptions = {
    center: { lat: 29.085658, lng: -110.971709 },
    zoom: 11,
    mapTypeId: MapTypeId.ROADMAP,
    disableDefaultUI: true
  };
  allDrivers: any[] = [];
  driversMarkers: any[] = [];
  markerSelected: any;

  constructor(private _driver: DriverService) {
  }

  async ngOnInit(): Promise<void> {
    this.allDrivers = await this._driver.getAll().pipe(take(1)).toPromise();

    this._driver.listenDriversLocation().subscribe((drivers: any[]) => {
      let markers = [];

      drivers.forEach((driver: any) => {
        console.log(driver);
        markers.push({
          position: {
            lat: driver.payload.val().latitude,
            lng: driver.payload.val().longitude
          },
          lastUpdate: driver.payload.val().lastUpdate,
          driver: this.allDrivers.find(d => d.key === driver.key)
        });
      });

      this.driversMarkers = markers;
    });
  }

  openInfoWindow(marker: MapMarker, driver: any) {
    this.markerSelected = driver;
    this.infoWindow.open(marker);
  }
}
