import { Injectable } from '@angular/core';
import { FirebaseDataService } from "../../shared/services/firebase-data.service";
import { AngularFirestore } from "@angular/fire/compat/firestore";

@Injectable({
  providedIn: 'root'
})
export class UserService extends FirebaseDataService {

  constructor(private _db: AngularFirestore) {
    super(_db, null);
  }

  getAll() {
    return this.colWithIds$('users', ref => ref.where('trash', '==', false));
  }
}
