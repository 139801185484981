import { Component, OnInit } from '@angular/core';
import { Driver } from "../../../../interfaces/driver";
import { Schedule } from "../../../../interfaces/schedule";
import { Observable } from "rxjs";
import { DataTableConfig } from "../../../../../shared/interfaces/data-table-config";
import { AssignService } from "../../services/assign.service";
import { map } from "rxjs/operators";
import { AlertService } from "../../../../../shared/services/alert.service";
import { ScheduleService } from "../../../../services/schedule.service";
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
  selector: 'app-driver-selection',
  templateUrl: './driver-selection.component.html',
  styleUrls: ['./driver-selection.component.scss']
})
export class DriverSelectionComponent implements OnInit {
  driver: Driver;
  schedule: Schedule
  drivers$: Observable<Driver[]> = new Observable<Driver[]>();
  config: DataTableConfig = {
    hasSearch: false,
    notFoundText: "No hay conductores disponibles",
    title: ""
  };

  constructor(private _assign: AssignService,
              private _schedule: ScheduleService,
              private modal: BsModalRef) {
  }

  ngOnInit(): void {
    this.drivers$ = this._assign.drivers$
      .pipe(
        map(drivers => drivers.filter(driver => driver.key !== this.driver.id))
      );
  }

  async handleSelectDriver(driver: Driver) {
    if (await AlertService.confirm('¿Está seguro de asignar este conductor?')) {
      await this._schedule.updateSchedule(this.schedule.route.key, this.schedule.key, {
        driver: this._schedule.getReference(`drivers/${driver.key}`)
      })

      AlertService.toastSuccess('Conductor asignado correctamente');
      this.modal.hide();
    }
  }
}
