import { Injectable } from '@angular/core';
import { FirebaseDataService } from "../../shared/services/firebase-data.service";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Observable } from "rxjs";
import { ISlider } from "../interfaces/slider";
import { ObjectService } from "../../shared/services/object.service";

@Injectable({
  providedIn: 'root'
})
export class SliderService extends FirebaseDataService {

  constructor(private _db: AngularFirestore,
              private _storage: AngularFireStorage) {
    super(_db, _storage);
  }

  getAll(): Observable<ISlider[]> {
    return this.colWithIds$('sliders', ref => ref
      .orderBy('position', 'asc')
      .where('trash', '==', false)
    );
  }

  async create(value: ISlider) {
    const id = this.createID()
    const url = await this.uploadFile(value.image, `sliders/${id}`);

    await this.set(`sliders/${id}`, {
      ...value,
      position: parseInt(value.position as string),
      image: url
    })
  }

  async updateSlider(key: string, value: ISlider) {
    let url = value.image;

    if (ObjectService.isFile(value.image)) url = await this.uploadFile(value.image, `sliders/${key}`);

    return this.update(`sliders/${key}`, {
      ...value,
      position: parseInt(value.position as string),
      image: url
    });
  }

  async deleteSlider(key: string) {
    return this.update(`sliders/${key}`, {
      trash: true
    });
  }
}
