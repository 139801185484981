import {Injectable} from '@angular/core';
import {FirebaseDataService} from "../../shared/services/firebase-data.service";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {Observable} from "rxjs";
import {Vehicle} from "../interfaces/vehicle";

@Injectable({
  providedIn: 'root'
})
export class VehicleService {

  constructor(private db: FirebaseDataService,
              private afs: AngularFirestore) {
  }

  getAll(): Observable<Vehicle[]> {
    return this.db.colWithIds$('vehicles', ref => ref.where('trash', '==', false))
  }

  get(vehicleKey: string): Observable<Vehicle> {
    return this.db.docWithId$(`vehicles/${vehicleKey}`);
  }

  update(vehicleKey: string, driver: Vehicle): Promise<void> {
    return this.afs.doc(`vehicles/${vehicleKey}`).update({
      ...driver,
      updatedAt: new Date().getTime()
    });
  }

  delete(vehicleKey: string): Promise<void> {
    return this.afs.doc(`vehicles/${vehicleKey}`).update({
      trash: true,
      updatedAt: new Date().getTime()
    });
  }

  set(vehicleKey: string, vehicle: Vehicle) {
    return this.afs.doc(`vehicles/${vehicleKey}`).set(vehicle, {merge: true});
  }

  getRef(vehicleKey: string) {
    return this.afs.doc(`vehicles/${vehicleKey}`).ref;
  }
}
