<app-datatable (rowSelected)="openEditDriverModal($event)" [config]="datatableConfig" [rows$]="drivers$">

  <app-datatable-column title="Nombre">
    <ng-template let-row #column>
      {{row.name + ' ' + row.lastName}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Correo">
    <ng-template let-row #column>
      {{row.email}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Teléfono">
    <ng-template let-row #column>
      {{row.phoneNumber ? row.phoneNumber : 'No registrado'}}
    </ng-template>
  </app-datatable-column>

  <app-data-table-option>
    <ng-template let-row #option>
      <div class="d-flex flex-row">
        <button class="btn btn-success btn-sm pl-a-half pr-a-half mr-1"
                ngbTooltip="Editar"
                (click)="openEditDriverModal(row)">
          <i class="fa fa-edit fa-lg"></i>
        </button>

        <button class="btn btn-danger btn-sm pl-a-half pr-a-half"
                ngbTooltip="Eliminar"
                (click)="delete(row)">
          <i class="fa fa-trash fa-lg"></i>
        </button>
      </div>
    </ng-template>
  </app-data-table-option>

</app-datatable>
<app-add-float-button (clickEmmiter)="openDriverModal()"></app-add-float-button>
