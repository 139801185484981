<div class="modal-header d-flex align-items-center flex-row">
  <h4 class="modal-title pull-left align-items-center">Configuración</h4>
  <button type="button" class="pull-right btn" (click)="modal.hide()">
    <i class="fa fa-times"></i>
  </button>
</div>
<div class="p-1">
  <form [formGroup]="settingsForm">
    <div class="mt-1">
      <label>Tiempo de espera máximo (minutos)</label>
      <input type="number"
             class="form-control"
             formControlName="waitTime"
             placeholder="Introduzca el tiempo máximo de espera"
             [ngClass]="{'is-invalid' : submitted && formControls.waitTime.errors}">
      <small class="invalid-feedback">Campo obligatorio</small>
    </div>

    <div class="mt-1 d-flex flex-row justify-content-end">
      <button [disabled]="loading" (click)="submit()" class="btn btn-primary btn-block" tabindex="4" rippleEffect>
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Guardar
      </button>
    </div>
  </form>
</div>
