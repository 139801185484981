import {Component, OnInit} from '@angular/core';
import {BsModalService} from "ngx-bootstrap/modal";
import {DataTableConfig} from "../../interfaces/data-table-config";
import {Observable} from "rxjs";
import {FieldDataTableColumnType} from "../../enums/field-data-table-column-type.enum";
import {FieldDataTableColumn} from "../../interfaces/field-data-table-column";
import {AlertService} from "../../services/alert.service";

@Component({
  selector: 'app-search-items',
  templateUrl: './search-items-modal.component.html',
  styleUrls: ['./search-items-modal.component.css']
})
export class SearchItemsModalComponent implements OnInit {
  items$: Observable<any>
  config: DataTableConfig = {hasSearch: true, notFoundText: "No hay resultados en la búsqueda", title: ""}
  fields: FieldDataTableColumn[] = [];
  itemSelected: any;
  fieldDataTableColumnTypeEnum = FieldDataTableColumnType;

  constructor(public modal: BsModalService) {
  }

  ngOnInit(): void {
  }

  async selectItem(item) {
    if (await AlertService.confirm('¿Estás seguro de que deseas seleccionar este elemento?')) {
      this.itemSelected = item;
      this.modal.hide(100);
    }
  }

}
