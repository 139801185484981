<app-datatable [rows$]="sliders$"
               [config]="dataTableConfig"
               (rowSelected)="handleSelectSlide($event)">
  <app-datatable-column title="Título">
    <ng-template let-row
                 #column>
      {{row?.title}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Posición">
    <ng-template let-row
                 #column>
      {{row?.position}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Acciones"
                        [isClickable]="false">
    <ng-template let-row
                 #column>
      <button class="btn btn-danger btn-sm"
              (click)="handleDeleteSlider(row)"><i class="fa fa-trash"></i></button>
    </ng-template>
  </app-datatable-column>
</app-datatable>

<app-add-float-button (clickEmmiter)="openSliderModal()"></app-add-float-button>
