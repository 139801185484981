import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import MapTypeId = google.maps.MapTypeId;

@Component({
  selector: 'app-station-map',
  templateUrl: './station-map.component.html',
  styleUrls: ['./station-map.component.scss']
})
export class StationMapComponent implements OnInit {
  @Output() addressEmitter = new EventEmitter<any>();
  @Input() marker = null;

  options: google.maps.MapOptions = {
    center: {lat: 29.085658, lng: -110.971709},
    zoom: 11,
    mapTypeId: MapTypeId.ROADMAP
  };

  markerOptions: google.maps.MarkerOptions = {draggable: false};
  markerPositions: google.maps.LatLngLiteral[] = [];

  constructor() {
  }

  ngOnInit(): void {
    if (!!this.marker) {
      this.markerPositions[0] = {...this.marker}
    }
  }

  addMarker(event: google.maps.MapMouseEvent) {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({'location': event.latLng}, (results, status) => {
        this.addressEmitter.emit({
          address: results[0].formatted_address,
          latitude: event.latLng.lat(),
          longitude: event.latLng.lng()
        })
      }
    )
    this.markerPositions[0] = event.latLng.toJSON()
  }
}
