import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {AuthService} from "../../app/shared/services/auth.service";

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(private router: Router,
              private _auth: AuthService) {
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    await this._auth.loadFirebaseUser();
    if (!this._auth.user) return true;

    const idToken = await this._auth.user.getIdTokenResult(true);

    if (!!idToken.claims.isAdmin) return this.router.navigateByUrl(`admin`);

    return true;
  }

}
