import {Component, OnInit} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {Driver} from "../../interfaces/driver";
import {DataTableConfig} from "../../../shared/interfaces/data-table-config";
import {ToastrService} from "ngx-toastr";
import {AlertService} from "../../../shared/services/alert.service";
import {DriverService} from "../../services/driver.service";
import {DriverModalComponent} from "../../modals/driver-modal/driver-modal.component";
import {BsModalService} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-drivers',
  templateUrl: './drivers.component.html',
  styleUrls: ['./drivers.component.scss']
})
export class DriversComponent implements OnInit {
  drivers$: Observable<Driver[]>;
  datatableConfig: DataTableConfig = {
    hasSearch: true,
    notFoundText: 'No se encontraron choferes registrados',
    title: 'Lista de choferes',
    sortByField: 'name'
  };

  constructor(private modal: BsModalService,
              private toastr: ToastrService,
              private _driver: DriverService) {
  }

  async ngOnInit() {
    this.drivers$ = this._driver.getAll();
  }

  openDriverModal() {
    this.modal.show(DriverModalComponent);
  }

  openEditDriverModal(driver: Driver) {
    this.modal.show(DriverModalComponent, {
      initialState: {
        isEdit: true,
        driver: {...driver}
      }
    });
  }

  async delete(driver: Driver) {
    if (!await AlertService.confirm('¿Estás seguro de que quieres eliminar este chofer?')) return;
    await this._driver.delete(driver.key);
    this.toastr.success('El chofer ha sido eliminado correctamente');
  }
}
