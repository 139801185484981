import { Injectable } from '@angular/core';
import { FirebaseDataService } from "../../shared/services/firebase-data.service";
import { Observable } from "rxjs";
import { Driver } from "../interfaces/driver";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { take } from "rxjs/operators";
import { AngularFireDatabase } from "@angular/fire/compat/database";

@Injectable({
  providedIn: 'root'
})
export class DriverService {

  constructor(private db: FirebaseDataService,
    private afs: AngularFirestore,
    private rl: AngularFireDatabase,
    private http: HttpClient) {
  }

  getAll(): Observable<Driver[]> {
    return this.db.colWithIds$('drivers', ref => ref.where('trash', '==', false));
  }

  get(driverKey: string): Observable<Driver> {
    return this.db.docWithId$(`drivers/${driverKey}`);
  }

  update(driverKey: string, driver: Driver): Promise<void> {
    return this.afs.doc(`drivers/${driverKey}`).update({
      ...driver,
      updatedAt: new Date().getTime()
    });
  }

  delete(driverKey: string): Promise<void> {
    return this.afs.doc(`drivers/${driverKey}`).update({
      trash: true,
      updatedAt: new Date().getTime()
    });
  }

  set(driverKey: string, driver: Driver) {
    return this.afs.doc(`drivers/${driverKey}`).set(driver, { merge: true });
  }

  createAccount(email: string): Promise<any> {
    return this.http.post(`${environment.apiUrl}/drivers/create`, {
      email,
      password: email
    }).pipe(take(1)).toPromise();
  }

  getRef(driverKey: string) {
    return this.afs.doc(`drivers/${driverKey}`).ref;
  }

  listenDriversLocation() {
    return this.rl.list('drivers').snapshotChanges();
  }
}
