<div class="modal-header d-flex align-items-center flex-row">
  <h4 class="modal-title pull-left align-items-center">Vehículo</h4>
  <button type="button" class="pull-right btn" (click)="modal.hide()">
    <i class="fa fa-times"></i>
  </button>
</div>
<div class="p-1">
  <form [formGroup]="vehicleForm">
   <div class="gallery">
    <div class="mt-1">
      <label>Matrícula</label>
      <input type="text" class="form-control" formControlName="carRegistration"
             placeholder="Matrícula del vehículo"
             [ngClass]="{'is-invalid' : submitted && formControls.carRegistration.errors}">
      <small class="invalid-feedback">Campo obligatorio</small>
    </div>

    <div class="mt-1">
      <label>Número de la unidad</label>
      <input type="number" class="form-control" formControlName="unitID" placeholder="Número de la unidad"
             [ngClass]="{'is-invalid' : submitted && formControls.unitID.errors}">
      <small class="invalid-feedback">Campo obligatorio</small>
    </div>

    <div class="mt-1">
      <label>Marca</label>
      <input type="text" class="form-control" formControlName="brand" placeholder="Marca del vehículo"
             [ngClass]="{'is-invalid' : submitted && formControls.brand.errors}">
      <small class="invalid-feedback">Campo obligatorio</small>
    </div>

    <div class="mt-1">
      <label>Modelo</label>
      <input type="text" class="form-control" formControlName="model" placeholder="Modelo del vehículo"
             [ngClass]="{'is-invalid' : submitted && formControls.model.errors}">
      <small class="invalid-feedback">Campo obligatorio</small>
    </div>

    <div class="mt-1">
      <label>Año</label>
      <input type="number" class="form-control" formControlName="yearModel" placeholder="Año del vehículo"
             [ngClass]="{'is-invalid' : submitted && formControls.yearModel.errors}">
      <small class="invalid-feedback">Campo obligatorio</small>
    </div>

    <div class="mt-1">
      <label>Cupos</label>
      <input type="number" class="form-control" formControlName="spots" placeholder="Cupos del vehículo"
             [ngClass]="{'is-invalid' : submitted && formControls.spots.errors}">
      <small class="invalid-feedback">Campo obligatorio</small>
    </div>
   </div>


    <div class="mt-1">
      <label>Detalles</label>
      <textarea
        class="form-control"
        formControlName="details"
        placeholder="Introduzca los detalles del vehículo"
        [ngClass]="{'is-invalid': submitted && formControls.details.errors}">
      </textarea>
    </div>

    <div class="mt-1">
      <app-upload-file-storage
        [routeFile]="routeFile"
        [showPreview]="true"
        (isPreview)="isPreview = $event"
        label="foto del vehículo"
        controlName="imageUrl"
        extension="png">
      </app-upload-file-storage>
    </div>

    <div class="w-100 d-flex justify-content-center">
      <img [src]="vehicle.imageUrl" class="w-25" alt="user photo" *ngIf="!isPreview && !!vehicle.imageUrl">
    </div>

    <div class="mt-1 d-flex flex-row justify-content-end">
      <button [disabled]="loading" (click)="submit()" class="btn btn-primary btn-block" tabindex="4" rippleEffect>
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Guardar
      </button>
    </div>
  </form>
</div>
