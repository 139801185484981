<div class="p-4">
  <ul class="timeline" *ngIf="log">
    <li class="timeline-item" *ngIf="!!log.arriveDate">
      <span class="timeline-point timeline-point-indicator"></span>
      <div class="timeline-event">
        <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
          <h6>Llegó a la estación</h6>
          <span class="timeline-event-time">{{log.arriveDate}}</span>
        </div>
      </div>
    </li>
    <li class="timeline-item" *ngIf="!!log.departDate">
      <span class="timeline-point timeline-point-danger timeline-point-indicator"></span>
      <div class="timeline-event">
        <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
          <h6>Salió de la estación</h6>
          <span class="timeline-event-time">{{log.departDate}}</span>
        </div>
      </div>
    </li>
  </ul>

</div>
