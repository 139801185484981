import {Routes} from '@angular/router';
import {AdminModule} from "./admin/admin.module";
import {AuthModule} from "./auth/auth.module";
import {LoginGuard} from "../@core/guards/login.guard";
import { AdminGuard } from '@core/guards/admin.guard';

export const appRoutes: Routes = [
  {
    path: 'auth',
    loadChildren: () => AuthModule,
    canActivate: [LoginGuard]
  },
  {
    path: 'admin',
    loadChildren: () => AdminModule,
    canActivate: [AdminGuard]
  },
  {
    path: '**',
    redirectTo: 'admin/drivers'
  },
];
