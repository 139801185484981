import { Component, OnInit } from '@angular/core';
import { DataTableConfig } from "../../../shared/interfaces/data-table-config";
import { Observable } from "rxjs";
import { UserService } from "../../services/user.service";

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  datatableConfig: DataTableConfig = {
    hasSearch: true,
    notFoundText: "No se encontraron usuarios registrados",
    title: "Usuarios"
  };
  users$: Observable<any[]> = new Observable<any[]>();

  constructor(private _user: UserService) {
  }

  ngOnInit(): void {
    this.users$ = this._user.getAll();
  }

  openUserDetails(user: any) {
console.log(user)
  }
}
