import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RouteService } from 'app/admin/services/route.service';
import { AlertService } from 'app/shared/services/alert.service';
import { ToastService } from 'app/shared/services/toast.service';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  settings;
  isEdit: boolean = false;
  submitted: boolean = false;
  loading: boolean = false;
  settingsForm: FormGroup;

  constructor(private formBuilder: FormBuilder,
              private afs: AngularFirestore,
              public modal: BsModalService,
              private _route: RouteService,
              private toastr: ToastService) {
    this.createSettingsForm()
   }

  ngOnInit() {
    if (this.isEdit) {
      this.settingsForm.patchValue({...this.settings});
    } else {
      this.settings.key = this.afs.createId();
    }
  }

  createSettingsForm() {
    this.settingsForm = this.formBuilder.group({
      waitTime: ['', Validators.required],      
      updatedAt: [new Date().getTime()],
      createdAt: [new Date().getTime()],
      trash: [false]
    })
  }

  get formControls() {
    return this.settingsForm.controls;
  }

  async submit() {
    this.submitted = true;
    if (!this.settingsForm.valid) return AlertService.toastError('Verifique sus datos');
    if (!await AlertService.confirm('Estás seguro de que deseas guardar este tiempo?')) return;

    this.loading = true; 

    await this._route.setSettings({waitTime: +this.settingsForm.value.waitTime});

    this.loading = false;
    this.modal.hide();
    AlertService.toastSuccess('Los datos se guardaron exitosamente');
  }
}
