<div class="mb-2">
  <ng-container [ngSwitch]="type">

    <ng-container *ngSwitchCase="'select'">
      <ng-select
        [placeholder]="placeholder"
        [items]="items"
        [multiple]="multiple"
        [closeOnSelect]="false"
        [bindLabel]="bindLabel"
        [formControl]="formControl"></ng-select>
    </ng-container>

    <ng-container *ngSwitchCase="'file'">
      <ti-file
        [routeFile]="routeFile"
        [showPreview]="true"
        [label]="label"
        [formControl]="formControl">
      </ti-file>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <label class="form-label font-W">{{label}}</label>

      <input [type]="type"
             class="form-control"
             [formControl]="formControl"
             [ngClass]="{ 'is-invalid': form.submitted && formControl.errors }">

      <small class="form-text text-muted danger invalid-feedback"
             *ngIf="form.submitted && formControl.errors">
        {{getMessageError(formControl.errors)}}
      </small>
    </ng-container>
  </ng-container>
</div>
