<app-datatable
  [config]="datatableConfig"
  [rows$]="logs$">

  <app-datatable-column title="Ruta">
    <ng-template let-row
                 #column>
      {{(row.route | doc | async)?.name}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Conductor">
    <ng-template let-row
                 #column>
      {{(row.driver | doc | async)?.name}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Vehiculo">
    <ng-template let-row
                 #column>
      {{(row.vehicle | doc | async)?.carRegistration}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Estaciones">
    <ng-template let-row
                 #column>
      <div class="d-flex">
        <div *ngFor="let log of row.schedule"
             class="ml-1"
             [ngClass]="{
              'bg-danger': !log.arriveDate,
              'bg-success': !!log.arriveDate
           }"
             style="height: 20px; width: 20px;border-radius: 100px"
             tooltip="{{log.hour}}"
             (click)="openLog(log)">
        </div>
      </div>
    </ng-template>
  </app-datatable-column>


</app-datatable>
