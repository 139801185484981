<div class="position-relative">
  <input type="text"
         class="form-control {{cssClass}}"
         placeholder="{{placeholder}}" [(ngModel)]="wordToSearch"
         (ngModelChange)="searchWord()"
         (click)="showArrayList()"
         (blur)="hideArrayList()">
  <div class="list shadow w-100" *ngIf="showArray">
    <div class="body-1 d-block p-1 pl-1 pr-1 border-bottom cursor-pointer"
         *ngFor="let item of arrayFiltered"
         (click)="emmitSelected(item)">
      {{ item[propertyToSearch] }}
    </div>
  </div>
</div>
