<div class="modal-header d-flex align-items-center flex-row">
  <h4 class="modal-title pull-left align-items-center">Ruta</h4>
  <button type="button"
          class="pull-right btn"
          (click)="modal.hide()">
    <i class="fa fa-times"></i>
  </button>
</div>
<section class="vertical-wizard m-0"
         [formGroup]="routeForm">
  <div id="stepper2"
       class="bs-stepper vertical">
    <app-vertical-stepper></app-vertical-stepper>

    <div class="bs-stepper-content">
      <app-general-info></app-general-info>

      <app-stations-route-modal></app-stations-route-modal>

      <app-schedules-stations></app-schedules-stations>

      <app-map-preview [route]="routeForm.value"></app-map-preview>
    </div>
  </div>
</section>

