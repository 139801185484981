<app-datatable
  (rowSelected)="openEditRouteModal($event)"
  [config]="datatableConfig"
  [rows$]="routes$">

  <app-datatable-column title="Nombre">
    <ng-template let-row #column>
      {{row.name}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Tipo de ruta">
    <ng-template let-row #column>
      {{row.isEventual ? 'Eventual' : 'Permanente'}}
    </ng-template>
  </app-datatable-column>

  <app-data-table-option>
    <ng-template let-row #option>
      <div class="d-flex flex-row">
        <button class="btn btn-success btn-sm pl-a-half pr-a-half mr-1"
                ngbTooltip="Editar"
                (click)="openEditRouteModal(row)">
          <i class="fa fa-edit fa-lg"></i>
        </button>

        <button class="btn btn-danger btn-sm pl-a-half pr-a-half"
                ngbTooltip="Eliminar"
                (click)="delete(row)">
          <i class="fa fa-trash fa-lg"></i>
        </button>
      </div>
    </ng-template>
  </app-data-table-option>

</app-datatable>

<button
  class="btn btn-dark config"
  app-tooltip="Configurar"
  (click)="openConfig()">
  <i class="fa fa-gear"></i>
</button>

<app-add-float-button (clickEmmiter)="openRouteModal()"></app-add-float-button>
