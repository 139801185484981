<div class="gantt_wrapper">
  <div class="gantt_wrapper__row"
       [style.height]="(item.groups?.length || 1) * 30 + 'px'"
       *ngFor="let item of items">
    <div class="gantt_wrapper__column"
         *ngFor="let column of columns"
         [style.min-width]="(column.isFixed
            ? options.fixedColumnMinWidth
            : column.isColumnGroup
            ? options.fixedColumnGroupMinWidth
            : options.columnWidth) + 'px'"
         [style.max-width]="(column.isFixed
            ? options.fixedColumnMaxWidth
            : column.isColumnGroup
            ? options.fixedColumnGroupMaxWidth
            : options.columnWidth) + 'px'"
         [ngClass]="{
           'gantt_wrapper__column--header': item.isHeader,
           'gantt_wrapper__column--fixed': column.isFixed
         }">
      <div *ngIf="column.isFixed">
        <ng-container
          [ngTemplateOutlet]="fixedColumnTemplate"
          [ngTemplateOutletContext]="{$implicit: item}">
        </ng-container>
      </div>
      <span *ngIf="item.isHeader">{{column.name}}</span>

      <ng-container *ngIf="item.groups && !column.isFixed">
        <div class="gantt_wrapper__column bg-white d-flex justify-content-center align-items-center cursor-pointer"
             *ngFor="let group of item.groups"
             (click)="handleGroupItemClick(item, group)">
          <ng-container
            *ngIf="column.isColumnGroup"
            [ngTemplateOutlet]="fixedColumnGroupTemplate"
            [ngTemplateOutletContext]="{$implicit: group}">
          </ng-container>

          <ng-container *ngIf="!column.isColumnGroup">
            <div *ngIf="!isActive(item, group)"
                 class="w-100"></div>

            <div class="gantt_wrapper__column--task"
                 [style.background-color]="isActive(column, group) ? group.bgColor : 'transparent'"
                 *ngIf="isActive(column, group)"
                 ngbTooltip="{{group.startHour + '-' + group.endHour}}"
                 [ngClass]="{
                   'gantt_wrapper__column--task--active': isActive(column, group),
                   'gantt_wrapper__column--task--first': isFirst(column, group),
                   'gantt_wrapper__column--task--last': isLast(column, group)
                 }"></div>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</div>
