import { Component, OnInit } from '@angular/core';
import { Observable, of } from "rxjs";
import { DataTableConfig } from "../../../shared/interfaces/data-table-config";
import { BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { AlertService } from "../../../shared/services/alert.service";
import { Route } from "../../interfaces/route";
import { RouteModalComponent } from "./modals/route-modal/route-modal.component";
import { RouteService } from "../../services/route.service";
import { first, map } from "rxjs/operators";
import { DriverService } from "../../services/driver.service";
import { Driver } from "../../interfaces/driver";
import { Vehicle } from "../../interfaces/vehicle";
import { VehicleService } from "../../services/vehicle.service";
import { SettingsComponent } from 'app/admin/modals/settings/settings.component';

@Component({
  selector: 'app-routes',
  templateUrl: './routes.component.html',
  styleUrls: ['./routes.component.scss']
})
export class RoutesComponent implements OnInit {
  routes$: Observable<Route[]> = of([]);
  drivers: Driver[] = [];
  vehicles: Vehicle[] = [];
  settings;

  datatableConfig: DataTableConfig = {
    hasSearch: true,
    notFoundText: 'No se encontraron rutas registradas',
    title: 'Lista de rutas',
    sortByField: 'name'
  };

  constructor(private modal: BsModalService,
              private toastr: ToastrService,
              private _route: RouteService,
              private _vehicle: VehicleService,
              private _driver: DriverService) {
  }

  async ngOnInit(): Promise<void> {
    this.drivers = await this._driver.getAll().pipe(first()).toPromise();
    this.vehicles = await this._vehicle.getAll().pipe(first()).toPromise();
    this.routes$ = this._route.getAll().pipe(map(routes => routes.map(route => ({
      ...route,
    }))));
    this.settings = await this._route.getSettings();
    if (!this.settings) await this._route.addSettings({waitTime: 1});
  }

  openRouteModal() {
    this.modal.show(RouteModalComponent, {
        class: 'modal-lg',
        ignoreBackdropClick: true,
      }
    );
  }

  openEditRouteModal(route: Route) {
    this.modal.show(RouteModalComponent, {
      initialState: {
        isEdit: true,
        route: {...route}
      },
      ignoreBackdropClick: true,
      class: 'modal-lg'
    });
  }

  async delete(route: Route) {
    if (!await AlertService.confirm('¿Estás seguro de que quieres eliminar esta ruta?')) return;
    await this._route.delete(route.key);
    this.toastr.success('La ruta ha sido eliminada correctamente');
  }

  async openConfig() {
    this.settings = await this._route.getSettings();

    this.modal.show(SettingsComponent, {
      initialState: {
        settings: {...this.settings},
        isEdit: !!this.settings
      },
      ignoreBackdropClick: true,
      class: 'modal-sm'
    });
  }
}
