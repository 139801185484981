import { Component, OnInit } from '@angular/core';
import { Observable } from "rxjs";
import { ISlider } from "../../interfaces/slider";
import { DataTableConfig } from "../../../shared/interfaces/data-table-config";
import { SliderService } from 'app/admin/services/slider.service';
import { BsModalService } from "ngx-bootstrap/modal";
import { SliderModalComponent } from 'app/admin/modals/slider-modal/slider-modal.component';
import { AlertService } from "../../../shared/services/alert.service";

@Component({
  selector: 'app-welcome-sliders',
  templateUrl: './welcome-sliders.component.html',
  styleUrls: ['./welcome-sliders.component.scss']
})
export class WelcomeSlidersComponent implements OnInit {
  sliders$: Observable<ISlider[]> = this._slider.getAll();
  dataTableConfig: DataTableConfig = {
    title: '',
    notFoundText: 'No hay sliders registrados',
    hasSearch: false,
  };

  constructor(private _slider: SliderService,
              private modal: BsModalService) {
  }

  ngOnInit(): void {
  }

  handleSelectSlide(slider: ISlider) {
    this.modal.show(SliderModalComponent, {
      initialState: {
        slider
      },
      class: 'modal-lg'
    })
  }

  openSliderModal() {
    this.modal.show(SliderModalComponent, {
      class: 'modal-lg',
    });
  }

  async handleDeleteSlider(row: ISlider) {
    if (await AlertService.confirm('¿Estás seguro de eliminar este slider?')) {
      await this._slider.deleteSlider(row.key);

      AlertService.toastSuccess('Slider eliminado correctamente');
    }
  }
}
