import {Pipe, PipeTransform} from '@angular/core';
import {Observable} from 'rxjs';
import {FirebaseDataService} from '../services/firebase-data.service';

@Pipe({
  name: 'col'
})
export class ColPipe implements PipeTransform {

  constructor(private db: FirebaseDataService) {
  }

  transform(reference: string): Observable<any> {
    return this.db.colWithIds$(reference, ref => ref
      .where('trash', '==', false));
  }
}
