<div id="stations"
     class="content">
  <div class="content-header">
    <h5 class="mb-0">Estaciones</h5>
    <small class="text-muted">Elegir las estaciones que marcarán la ruta</small>
  </div>

  <div class="mt-1">
    <label>Estaciones</label>
    <ng-select
      placeholder="Seleccionar estaciones"
      [items]="routeModal.stations"
      [multiple]="true"
      [closeOnSelect]="false"
      bindLabel="name"
      (remove)="removeStationSchedules($event)"
      (add)="addStationSchedule($event)"
      [formControl]="routeModal.getFormControl('stations')"></ng-select>
  </div>

  <div
    class="row mt-2"
    id="table-small"
    *ngIf="!!routeModal.routeForm.value.stations && routeModal.routeForm.value.stations.length">

    <div class="stations-list"
         cdkDropList
         (cdkDropListDropped)="drop($event)"
         [cdkDropListData]="routeModal.routeForm.value.stations">
      <div
        *ngFor="let station of routeModal.routeForm.value.stations; index as index"
        class="station-box py-2 px-1 shadow"
        cdkDrag>
        <div class="d-flex flex-row justify-content-between w-100" (click)="addPrice(index)">
          <div class="w-100 d-flex flex-row">
            <div class="station-image-box d-flex justify-content-center align-items-center">
              <i
                *ngIf="!station.imageUrl"
                data-feather="map-pin"></i>
              <img
                class="w-100 h-100"
                *ngIf="!!station.imageUrl"
                [src]="station.imageUrl"
                alt="image"
                [viewerOptions]="viewerOptions"
                ngxViewer/>
            </div>

            <div class="d-flex flex-column ml-1">
              <span class="font-weight-bold font-small-2">{{station.name}}</span>
              <span class="font-small-1">{{station.address}}</span>
            </div>
          </div>
          <div class="d-flex flex-column ml-2">
            <span class="font-small-1">Precio</span>
            <span class="font-weight-bold font-small-2">${{(station?.price || 0).toFixed(2)}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-between mt-3">
    <button class="btn btn-outline-secondary btn-prev"
            (click)="routeModal.previousWizard()"
            rippleEffect>
      <i data-feather="arrow-left"
         class="align-middle mr-sm-25 mr-0"></i>
      <span class="align-middle d-sm-inline-block d-none">Anterior</span>
    </button>
    <button class="btn btn-primary btn-next"
            [disabled]="routeModal.formControls.stations.errors"
            (click)="routeModal.verticalWizardNext()"
            rippleEffect>
      <span class="align-middle d-sm-inline-block d-none">Siguiente</span>
      <i data-feather="arrow-right"
         class="align-middle ml-sm-25 ml-0"></i>
    </button>
  </div>
</div>
