import {Injectable} from '@angular/core';
import {FirebaseDataService} from "../../shared/services/firebase-data.service";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {Observable} from "rxjs";
import {Station} from "../interfaces/station";

@Injectable({
  providedIn: 'root'
})
export class StationService {
  constructor(private db: FirebaseDataService,
              private afs: AngularFirestore) {
  }

  getAll(): Observable<Station[]> {
    return this.db.colWithIds$('stations', ref => ref.where('trash', '==', false))
  }

  get(stationKey: string): Observable<Station> {
    return this.db.docWithId$(`stations/${stationKey}`);
  }

  update(stationKey: string, driver: Station): Promise<void> {
    return this.afs.doc(`stations/${stationKey}`).update({
      ...driver,
      updatedAt: new Date().getTime()
    });
  }

  delete(stationKey: string): Promise<void> {
    return this.afs.doc(`stations/${stationKey}`).update({
      trash: true,
      updatedAt: new Date().getTime()
    });
  }

  set(stationKey: string, station: Station) {
    return this.afs.doc(`stations/${stationKey}`).set(station, {merge: true});
  }

  getRef(stationKey: string) {
    return this.db.doc(`stations/${stationKey}`).ref
  }
}
