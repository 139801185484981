<div class="modal-header d-flex align-items-center flex-row">
  <h4 class="modal-title pull-left align-items-center">Horario</h4>
  <button type="button"
          class="pull-right btn"
          (click)="closeModal()">
    <i class="fa fa-times"></i>
  </button>
</div>
<div class="p-1">
  <div class="mt-1"
       [formGroup]="scheduleForm">
    <label>Nombre</label>
    <input type="text"
           class="form-control"
           formControlName="name"
           placeholder="Introduzca el nombre del horario"
           [ngClass]="{'is-invalid' : submitted && formControls.name.errors}">
    <small class="invalid-feedback">Campo obligatorio</small>
  </div>

  <div class="card p-2 mt-3">
    <div [ngClass]="{'border-bottom': !last}"
         *ngFor="let stationSchedule of schedules.schedule; index as index; last as last"
         (click)="openScheduleSelectorModal(stationSchedule)">
      <div class="d-flex flex-row justify-content-between align-items-center my-2">
        <div class="w-100 d-flex flex-row">
          <span class="font-weight-bolder mr-1">{{index + 1}}</span>

          <div class="d-flex flex-column">
            <span class="font-weight-bolder">{{stationSchedule.station.name}}</span>
            <span class="font-small-1">{{stationSchedule.station.address}}</span>
          </div>
        </div>

        <div class="d-flex flex-column ml-1 justify-content-center schedules-box">
          <span class="font-weight-bold font-small-2">Horarios</span>
          <span class="font-small-1"
                *ngFor="let schedule of stationSchedule.hours; index as index">{{schedule}}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="mt-1 d-flex flex-row justify-content-end">
    <button [disabled]="loading"
            (click)="submit()"
            class="btn btn-primary btn-block"
            tabindex="4"
            rippleEffect>
      <span *ngIf="loading"
            class="spinner-border spinner-border-sm mr-1"></span>Guardar
    </button>
  </div>
</div>
