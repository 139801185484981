import {Component, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {DataTableConfig} from "../../../shared/interfaces/data-table-config";
import {BsModalService} from "ngx-bootstrap/modal";
import {ToastrService} from "ngx-toastr";
import {AlertService} from "../../../shared/services/alert.service";
import {Station} from "../../interfaces/station";
import {StationService} from "../../services/station.service";
import {StationModalComponent} from "../../modals/station-modal/station-modal.component";

@Component({
  selector: 'app-stations',
  templateUrl: './stations.component.html',
  styleUrls: ['./stations.component.scss']
})
export class StationsComponent implements OnInit {
  stations$: Observable<Station[]>;
  datatableConfig: DataTableConfig = {
    hasSearch: true,
    notFoundText: 'No se encontraron estaciones registradas',
    title: 'Lista de estaciones',
    sortByField: 'name'
  };

  constructor(private modal: BsModalService,
              private toastr: ToastrService,
              private _station: StationService) {
  }

  async ngOnInit() {
    this.stations$ = this._station.getAll();
  }

  openStationModal() {
    this.modal.show(StationModalComponent);
  }

  openEditStationModal(station: Station) {
    this.modal.show(StationModalComponent, {
      initialState: {
        isEdit: true,
        station: {...station}
      }
    });
  }

  async delete(station: Station) {
    if (!await AlertService.confirm('¿Estás seguro de que quieres eliminar esta estación?')) return;
    await this._station.delete(station.key);
    this.toastr.success('La estación ha sido eliminado correctamente');
  }
}
