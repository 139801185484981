import { Component, OnInit } from '@angular/core';
import { Driver } from 'app/admin/interfaces/driver';
import { AssignService } from "../../services/assign.service";
import { Schedule } from "../../../../interfaces/schedule";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { DataTableConfig } from "../../../../../shared/interfaces/data-table-config";
import { ScheduleService } from "../../../../services/schedule.service";
import { AlertService } from "../../../../../shared/services/alert.service";
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
  selector: 'app-schedule-selection',
  templateUrl: './schedule-selection.component.html',
  styleUrls: ['./schedule-selection.component.scss']
})
export class ScheduleSelectionComponent implements OnInit {
  driver: Driver;
  vehicle: any;
  isVehicle: boolean;
  schedules$: Observable<Schedule[]> = new Observable<Schedule[]>();
  config: DataTableConfig = {
    hasSearch: false,
    notFoundText: "No hay horarios disponibles",
    title: ''
  };

  constructor(private _assign: AssignService,
              private _schedule: ScheduleService,
              private modal: BsModalRef) {
  }

  ngOnInit(): void {
    this.schedules$ = this._assign.schedules$
      .pipe(
        map(schedules => this.isVehicle
          ? schedules.filter(schedule => !schedule.vehicle)
          : schedules.filter(schedule => !schedule.driver))
      )
  }

  async handleScheduleSelected(schedule: Schedule) {
    if (await AlertService.confirm('¿Está seguro de asignar este horario?')) {
      await this._schedule.updateSchedule(schedule.route.key, schedule.key,
        this.isVehicle
          ? { vehicle: this._assign.getReference(`vehicles/${this.vehicle.id}`) }
          : { driver: this._assign.getReference(`drivers/${this.driver.id}`) }
      )

      AlertService.toastSuccess('Horario asignado correctamente')
      this.modal.hide()
    }
  }
}
