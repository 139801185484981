import {Component, OnInit} from '@angular/core';
import {DataTableConfig} from "../../../shared/interfaces/data-table-config";
import {FirebaseDataService} from "../../../shared/services/firebase-data.service";
import moment from "moment";
import { Observable, of } from "rxjs";
import {BsModalService} from "ngx-bootstrap/modal";
import {LogComponent} from "../../modals/log/log.component";

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements OnInit {
  datatableConfig: DataTableConfig = {
    hasSearch: true,
    notFoundText: 'No hay rutas en curso',
    title: 'Lista de rutas',
    sortByField: 'name'
  };
  logs$ = new Observable();

  constructor(private _db: FirebaseDataService,
              private modal: BsModalService) {
  }

  ngOnInit(): void {
    this._db.colGroup('logs')
      .snapshotChanges()
      .subscribe(docs => {
        const data: any[] = []

        docs.forEach(doc => {
          const item: any = doc.payload.doc.data();

          data.push({
            key: doc.payload.doc.id,
            route: this._db.getReference(`routes/${doc.payload.doc.ref.parent.parent.parent.parent.id}`),
            ...item
          })
        })

        this.logs$ = of(data
          .filter((log: any) => log.key == moment().format('DD-MM-YYYY'))
          .filter(log => !!log.driver)
        )
      })
  }

  openLog(log) {
    this.modal.show(LogComponent, {
      initialState: {
        log
      }
    })
  }

}
