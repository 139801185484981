<div id="general-info"
     class="content">
  <div class="content-header">
    <h5 class="mb-0">Información general</h5>
    <small class="text-muted">Agregar información general de la ruta, como nombre, días de la semana que circulará
      etc.</small>
  </div>
  <div class="row">
    <div class="col-12">
      <label>Nombre de la ruta</label>
      <input type="text"
             class="form-control"
             [formControl]="routeModal.getFormControl('name')"
             placeholder="Introduzca el nombre de la ruta"
             [ngClass]="{'is-invalid' : routeModal.submitted && routeModal.formControls.name.errors}">
      <small *ngIf="routeModal.submitted && routeModal.formControls.name.errors"
             class="invalid-feedback">Campo obligatorio</small>
    </div>

    <div class="col-12 mt-1">
      <label>Días de la semana</label>
      <ng-select
        placeholder="Seleccionar días de la semana"
        [items]="weekDays"
        [multiple]="true"
        [closeOnSelect]="false"
        bindLabel="label"
        [formControl]="routeModal.getFormControl('weekDays')"></ng-select>
    </div>

    <div class="col-12 mt-1">
      <label>Color de la ruta</label>
      <input type="color"
             class="form-control"
             [formControl]="routeModal.getFormControl('color')"
             placeholder="Seleccione un color para la ruta"
             [ngClass]="{'is-invalid' : routeModal.submitted && routeModal.formControls.color.errors}">
      <small *ngIf="routeModal.submitted && routeModal.formControls.color.errors"
             class="invalid-feedback">Campo obligatorio</small>
    </div>

    <div class="col-sm-12 mt-2"
         *ngIf="routeModal.routeForm.value.isEventual">
      <label>Seleccione el rango de fechas que durará la ruta activa</label>
      <ng2-flatpickr
        [formControl]="routeModal.getFormControl('datesRange')"
        style="background-color: white !important;"
        [config]="DateRangeOptions"
        name="DateRange"
        placeholder="Seleccionar un rango de fechas"></ng2-flatpickr>
    </div>
  </div>

  <div class="d-flex justify-content-between mt-3">
    <button class="btn btn-outline-secondary btn-prev"
            disabled
            rippleEffect>
      <i data-feather="arrow-left"
         class="align-middle mr-sm-25 mr-0"></i>
      <span class="align-middle d-sm-inline-block d-none">Anterior</span>
    </button>
    <button class="btn btn-primary btn-next"
            [disabled]="routeModal.formControls.name.errors || routeModal.formControls.weekDays.errors"
            (click)="routeModal.verticalWizardNext()"
            rippleEffect>
      <span class="align-middle d-sm-inline-block d-none">Siguiente</span>
      <i data-feather="arrow-right"
         class="align-middle ml-sm-25 ml-0"></i>
    </button>
  </div>
</div>
