// imports
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HttpClientModule } from '@angular/common/http';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { NgSelectModule } from '@ng-select/ng-select';

import { AddFloatButtonComponent } from './components/add-float-button/add-float-button.component';
import { FilterPipe } from './pipes/filter.pipe';
import { UploadFileStorageComponent } from './template-components/upload-file-storage/upload-file-storage.component';
import { NotFoundComponent } from './template-components/not-found/not-found.component';
import { DocPipe } from './pipes/doc.pipe';
import { FastSearchComponent } from './template-components/fast-search/fast-search.component';
import { ConfirmationDialogService } from './services/confirmation-dialog.service';
import { NavbarService } from './services/navbar.service';
import { SearchItemsModalComponent } from './template-components/search-items/search-items-modal.component';
import { DataTableComponent } from './components/data-table/data-table.component';
import { DataTableColumnComponent } from './components/data-table-column/data-table-column.component';
import { DataTableOptionComponent } from './components/data-table-option/data-table-option.component';
import { ColPipe } from './pipes/col.pipe';
import { SortPipe } from "./pipes/sort.pipe";
import { CoreDirectivesModule } from "../../@core/directives/directives";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { TiFormComponent } from "./components/ti-form/ti-form.component";
import { TiInputComponent } from "./components/ti-input/ti-input.component";
import { TiFileComponent } from "./template-components/ti-file/ti-file.component";
import { MatTabsModule } from "@angular/material/tabs";

@NgModule({
  declarations: [
    NotFoundComponent,
    DocPipe,
    ColPipe,
    FastSearchComponent,
    AddFloatButtonComponent,
    FilterPipe,
    UploadFileStorageComponent,
    SearchItemsModalComponent,
    DataTableComponent,
    DataTableColumnComponent,
    DataTableOptionComponent,
    SortPipe,
    TiFormComponent,
    TiInputComponent,
    TiFileComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    TooltipModule,
    CoreDirectivesModule,
    MatTabsModule
  ],
  exports: [
    // COMPONENTS
    NotFoundComponent,
    FastSearchComponent,
    SearchItemsModalComponent,
    AddFloatButtonComponent,
    UploadFileStorageComponent,
    DataTableComponent,
    DataTableColumnComponent,
    DataTableOptionComponent,
    TiFormComponent,
    TiInputComponent,
    TiFileComponent,

    // PIPES
    DocPipe,
    FilterPipe,
    ColPipe,
    SortPipe,

    // MODULES
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    PerfectScrollbarModule,
    NgbModule,
    NgSelectModule,
    MatTabsModule
  ],
  providers: [
    ConfirmationDialogService,
    NavbarService,
    DatePipe
  ]
})

export class SharedModule {
  static forRoot() {
    return {
      ngModule: SharedModule
    };
  }
}
