import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from 'app/shared/services/auth.service';
import { AlertService } from "../../app/shared/services/alert.service";

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor(
    private router: Router,
    private _auth: AuthService
  ) {
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    await this._auth.loadFirebaseUser();

    if (!this._auth.user) {
      return this.router.navigateByUrl('auth');
    }

    const idToken = await this._auth.user.getIdTokenResult(true);

    if (!idToken.claims.isAdmin) {
      await this._auth.signOut();
      AlertService.toastError('No tienes permisos para acceder a esta sección')
      return this.router.navigateByUrl('auth');
    }

    return true;
  }
}
