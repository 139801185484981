import {Component, OnInit} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {DataTableConfig} from "../../../shared/interfaces/data-table-config";
import {BsModalService} from "ngx-bootstrap/modal";
import {ToastrService} from "ngx-toastr";
import {AlertService} from "../../../shared/services/alert.service";
import {VehicleModalComponent} from "../../modals/vehicle-modal/vehicle-modal.component";
import {Vehicle} from "../../interfaces/vehicle";
import {VehicleService} from "../../services/vehicle.service";

@Component({
  selector: 'app-vehicles',
  templateUrl: './vehicles.component.html',
  styleUrls: ['./vehicles.component.scss']
})
export class VehiclesComponent implements OnInit {
  vehicles$: Observable<Vehicle[]>;
  datatableConfig: DataTableConfig = {
    hasSearch: true,
    notFoundText: 'No se encontraron vehículos registrados',
    title: 'Lista de vehículos',
    sortByField: 'identifier'
  };

  constructor(private modal: BsModalService,
              private toastr: ToastrService,
              private _vehicle: VehicleService) {
  }

  async ngOnInit() {
    this.vehicles$ = this._vehicle.getAll();
  }


  openVehicleModal() {
    this.modal.show(VehicleModalComponent);
  }

  openEditVehicleModal(vehicle: Vehicle) {
    this.modal.show(VehicleModalComponent, {
      initialState: {
        isEdit: true,
        vehicle: {...vehicle}
      }
    });
  }

  async delete(vehicle: Vehicle) {
    if (!await AlertService.confirm('¿Estás seguro de que quieres eliminar este vehículo?')) return;
    await this._vehicle.delete(vehicle.key);
    this.toastr.success('El vehículo ha sido eliminado correctamente');
  }
}
