import { Component, OnDestroy, OnInit } from '@angular/core';
import { DriverService } from "../../services/driver.service";
import { AssignService } from "./services/assign.service";
import { Subscription } from "rxjs";
import { ScheduleService } from "../../services/schedule.service";
import { VehicleService } from "../../services/vehicle.service";

@Component({
  selector: 'app-assigns',
  templateUrl: './assigns.component.html',
  styleUrls: ['./assigns.component.scss']
})
export class AssignsComponent implements OnInit, OnDestroy {
  scheduleSubscription: Subscription = new Subscription();
  driverSubscription: Subscription = new Subscription();
  vehicleSubscription: Subscription = new Subscription();

  constructor(
    private _driver: DriverService,
    private _schedule: ScheduleService,
    private _vehicle: VehicleService,
    private _assign: AssignService
  ) {
  }

  ngOnInit(): void {
    this.scheduleSubscription = this._schedule.getAll().subscribe(async schedules => {
      const decoratedSchedules = await this._assign.populate(schedules, ['route']);
      const activeSchedules = decoratedSchedules.filter(schedule => !schedule.route.trash);

      this._assign.schedules$.next(activeSchedules);
    });

    this.driverSubscription = this._driver.getAll().subscribe(drivers => {
      this._assign.drivers$.next(drivers);
    });

    this.vehicleSubscription = this._vehicle.getAll().subscribe(vehicles => {
      this._assign.vehicles$.next(vehicles);
    });
  }

  ngOnDestroy(): void {
    this.scheduleSubscription.unsubscribe();
    this.driverSubscription.unsubscribe();
  }

}
