<div class="p-4">
  <h4 class="mb-2">Slider de bienvenida</h4>

  <ti-form
    [submitted]="submitted"
    (submit)="submit()"
    [form]="sliderForm">

    <ti-input
      name="title"
      label="Título"></ti-input>

    <ti-input
      name="description"
      label="Descripción"></ti-input>

    <ti-input
      name="position"
      type="number"
      label="Posición"></ti-input>

    <ti-input
      name="image"
      type="file"
      label="Imagen"></ti-input>

  </ti-form>
</div>
