<app-datatable (rowSelected)="openEditStationModal($event)" [config]="datatableConfig" [rows$]="stations$">

  <app-datatable-column title="Nombre">
    <ng-template let-row #column>
      {{row.name}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Dirección">
    <ng-template let-row #column>
      {{row.address}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Latitud">
    <ng-template let-row #column>
      {{row.latitude}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Longitud">
    <ng-template let-row #column>
      {{row.longitude}}
    </ng-template>
  </app-datatable-column>

  <app-data-table-option>
    <ng-template let-row #option>
      <div class="d-flex flex-row">
        <button class="btn btn-success btn-sm pl-a-half pr-a-half mr-1"
                ngbTooltip="Editar"
                (click)="openEditStationModal(row)">
          <i class="fa fa-edit fa-lg"></i>
        </button>

        <button class="btn btn-danger btn-sm pl-a-half pr-a-half"
                ngbTooltip="Eliminar"
                (click)="delete(row)">
          <i class="fa fa-trash fa-lg"></i>
        </button>
      </div>
    </ng-template>
  </app-data-table-option>

</app-datatable>
<app-add-float-button (clickEmmiter)="openStationModal()"></app-add-float-button>
