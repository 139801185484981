import {Injectable} from '@angular/core';
import Swal, {SweetAlertInput, SweetAlertResult} from 'sweetalert2';
import {ToastrService} from 'ngx-toastr';

declare const $;

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private toastr: ToastrService) {
  }

  showSuccess(title: string, text: string = '') {
    this.toastr.success(title, text);
  }


  static error(title: string, text: string = ''): Promise<SweetAlertResult> {
    return Swal.fire({
      title,
      text,
      icon: 'error',
      confirmButtonText: 'Aceptar'
    });
  }

  static async info(title: string, text: string = '', html?: string): Promise<boolean> {
    return await Swal.fire({
      title,
      text,
      html,
      icon: 'info',
      confirmButtonText: 'Aceptar'
    }).then(result => !!result.value);
  }

  static async success(title: string, text: string = '', html?: string): Promise<boolean> {
    return await Swal.fire({
      title,
      text,
      html,
      icon: 'success',
      confirmButtonText: 'Aceptar'
    }).then(result => !!result.value);
  }

  static async confirm(title: string,
                       text: string = '',
                       confirmButtonText: string = 'Aceptar',
                       cancelButtonText: string = 'Cancelar'): Promise<boolean> {
    return await Swal.fire({
      title,
      text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText,
      cancelButtonText
    }).then((result) => {
      return !!result.value;
    });
  }

  static toastError(title: string, text: string = '', html?: string): void {
    Swal.fire({
      title,
      text,
      html,
      toast: true,
      icon: 'error',
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 4500
    });
  }

  static toastSuccess(title: string, text: string = '', html?: string): void {
    Swal.fire({
      title,
      text,
      html,
      toast: true,
      icon: 'success',
      position: 'top-end',
      showConfirmButton: false,
      timer: 4500
    });
  }

  static async deleteWithAdmin(title: string, text: string, code: string): Promise<string> {
    return new Promise(resolve => {
      Swal.fire({
        title,
        text,
        input: 'text',
        inputPlaceholder: code,
        showCancelButton: true,
        inputValidator: (result) => {
          if (result != code) return (result || !result) && 'El código es incorrecto';
        },
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Aceptar',
        preConfirm: (inputValue => {
          resolve(inputValue);
        })
      });
    });
  }

  static input(title: string, text: string = '', type: SweetAlertInput = 'text', confirmButtonText = 'Agregar'): Promise<any> {
    return new Promise(resolve => {
      Swal.fire({
        title,
        text,
        icon: 'info',
        input: type,
        confirmButtonText,
        preConfirm: (input) => {
          resolve(input);
        }
      });
    });
  }

  static inputValidator(title: string, text: string = '', confirmButtonText = 'Agregar'): Promise<string> {
    return new Promise(resolve => {
      Swal.fire({
        title,
        text,
        icon: 'info',
        input: 'text',
        confirmButtonText,
        preConfirm: (input) => {
          resolve(input);
        },
        inputValidator: (value) => {
          if (!value) {
            return 'Necesitas agregar algo!';
          }
        }
      });
    });
  }

  static async quantity(title: string, text: string = '', inputPlaceholder = 'Cantidad'): Promise<any> {
    return await Swal.fire({
      title,
      text,
      input: 'number',
      inputValue: '',
      inputPlaceholder,
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) return 'Necesitas escribir la cantidad a devolver';
      }
    }).then((result) => {
      return result.value;
    });
  }

  static async message(title: string, text: string = '', inputValue: string = ''): Promise<any> {
    return await Swal.fire({
      title,
      text,
      input: 'textarea',
      inputValue,
      inputPlaceholder: 'Escriba un mensaje',
      showCancelButton: true,
      inputAttributes: {
        'aria-label': 'Type your message here'
      },
      inputValidator: (value) => {
        if (!value) return 'Necesitas escribir un mensaje';
      }
    }).then((result) => {
      return result.value;
    });
  }

  static async inputs(title): Promise<any> {
    return await Swal.fire({
      title,
      html:
        '<input id="swal-input1" class="swal2-input" type="number" placeholder="Cantidad">' +
        '<textarea id="swal-input2" class="swal2-input" rows="10" placeholder="Observaciones">',
      preConfirm: function () {
        return new Promise(function (resolve) {
          resolve([
            $('#swal-input1').val(),
            $('#swal-input2').val()
          ]);
        });
      },
      showCancelButton: true,
    }).then((result) => {
      return result.value;
    });
  }

  static async withHtml(title, html, preConfirm): Promise<any> {
    return await Swal.fire({
      title,
      html,
      preConfirm,
      confirmButtonText: 'Aceptar',
      allowEscapeKey: true,
      allowOutsideClick: true,
      allowEnterKey: false
    }).then((result) => {
      return result.value;
    });
  }

  static async termsAndConditions(title: string): Promise<string> {
    return new Promise<string>(resolve => {
      Swal.fire({
        title,
        input: 'checkbox',
        inputPlaceholder: 'Estoy de acuerdo con los términos y condiciones',
        footer: '<a href="#/user/termsAndConditions" target="_blank">Términos y condiciones</a>',
        confirmButtonText:
          'Aceptar',
        inputValidator: (result) => {
          return !result && 'Debes estar de acuerdo con los T&C'
        },
        preConfirm: (inputValue => {
          resolve(inputValue)
        })
      })
    })
  }
}
