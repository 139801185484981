import { Component, OnInit } from '@angular/core';
import { Subscription } from "rxjs";
import { AssignService } from "../../services/assign.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { DocumentReference } from "@angular/fire/firestore";
import * as _ from "lodash";
import { ScheduleSelectionComponent } from "../../modals/schedule-selection/schedule-selection.component";
import { VehicleSelectionComponent } from "../../modals/vehicle-selection/vehicle-selection.component";
import { Vehicle } from "../../../../interfaces/vehicle";
import { AlertService } from "../../../../../shared/services/alert.service";

@Component({
  selector: 'app-vehicle',
  templateUrl: './vehicle.component.html',
  styleUrls: ['./vehicle.component.scss']
})
export class VehicleComponent implements OnInit {
  items = [];
  ganttOptions = {
    columnWidth: 47,
    fixedColumnMinWidth: 200,
    fixedColumnMaxWidth: 200,
    fixedColumnGroupMinWidth: 150,
    fixedColumnGroupMaxWidth: 150
  };
  vehicleSubscription: Subscription = new Subscription();
  scheduleSubscription: Subscription = new Subscription();

  constructor(public _assign: AssignService,
    private modal: BsModalService) {
  }

  ngOnInit(): void {
    this.vehicleSubscription = this._assign.vehicles$.subscribe(vehicles => {
      this.scheduleSubscription = this._assign.schedules$.subscribe(schedules => {
        this.items = vehicles.map(vehicle => {
          const decoratedSchedules = schedules
            .map(schedule => ({
              ...schedule,
              stationHours: schedule.schedule,
              schedule: undefined
            }))
            .filter(schedule => schedule.vehicle)
            .filter(schedule => (schedule.vehicle as DocumentReference).id === vehicle.key)
            .map(schedule => ({
              ...schedule,
              allHours: _.flatMap(schedule.stationHours.map(station => station.hours)).sort()
            }));

          return {
            id: vehicle.key,
            name: `${vehicle.brand} ${vehicle.model} ${vehicle.yearModel} - ${vehicle.unitID}`,
            isHeader: false,
            isFixed: false,
            groups: this._assign.decorateHoursGroup({ decoratedSchedules, vehicle })
          };
        });
      });
    });
  }

  ngOnDestroy(): void {
    this.vehicleSubscription.unsubscribe();
    this.scheduleSubscription.unsubscribe();
  }

  handleGroupItemClick({ item, group }) {
    this.modal.show(VehicleSelectionComponent, {
      initialState: {
        vehicle: item,
        schedule: group.data
      }
    });
  }

  handleVehicleClick(vehicle: Vehicle) {
    this.modal.show(ScheduleSelectionComponent, {
      initialState: {
        vehicle,
        isVehicle: true
      }
    });
  }
}
