import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {RouteModalComponent} from "../../route-modal.component";
import {CDK_DRAG_CONFIG, CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {Station} from "../../../../../../interfaces/station";
import _ from "lodash";
import {BsModalService} from "ngx-bootstrap/modal";
import {AlertService} from "../../../../../../../shared/services/alert.service";

const DragConfig = {
  zIndex: 100000
};

@Component({
  selector: 'app-stations-route-modal',
  templateUrl: './stations-route-modal.component.html',
  styleUrls: ['./stations-route-modal.component.scss'],
  providers: [{provide: CDK_DRAG_CONFIG, useValue: DragConfig}],
  encapsulation: ViewEncapsulation.None
})
export class StationsRouteModalComponent implements OnInit {
  viewerOptions: Viewer.Options = {
    transition: true
  }

  constructor(public routeModal: RouteModalComponent,
              private modal: BsModalService) {
  }

  ngOnInit(): void {
  }

  drop(event: CdkDragDrop<any[]>) {
    let stationsArray = _.cloneDeep([...this.routeModal.routeForm.value.stations]);
    moveItemInArray(stationsArray, event.previousIndex, event.currentIndex);
    this.routeModal.routeForm.patchValue({stations: _.cloneDeep(stationsArray)});
    this.orderAllSchedules(event)
  }

  orderAllSchedules(event: CdkDragDrop<any[]>) {

    for (let currentSchedule of this.routeModal.schedules) {
      moveItemInArray(currentSchedule.schedule, event.previousIndex, event.currentIndex);
    }
  }

  removeStationSchedules({value}: any) {
    this.routeModal.schedules = this.routeModal.schedules.map(currentSchedule => ({
      ...currentSchedule,
      schedule: currentSchedule.schedule.filter(({station}) => station.key != value.key)
    }))
  }

  addStationSchedule(stationsSelected: Station) {
    for (let currentSchedule of this.routeModal.schedules) {
      currentSchedule.schedule = [
        ...currentSchedule.schedule,
        {
          hours: [],
          station: {...stationsSelected}
        }
      ]
    }
  }

  async addPrice(index) {
    const price = await AlertService.input('Ingrese el precio de la estación actual a la siguiente', null, 'number');
    if (!price) return AlertService.toastError('Debe ingresar un precio');

    this.routeModal.routeForm.value.stations[index].price = Number(price);
  }
}
