<div class="modal-header d-flex align-items-center flex-row">
  <h4 class="modal-title pull-left align-items-center">Estación</h4>
  <button type="button" class="pull-right btn" (click)="modal.hide()">
    <i class="fa fa-times"></i>
  </button>
</div>
<div class="p-1">
  <form [formGroup]="stationForm">
    <div class="mt-1">
      <label>Nombre</label>
      <input type="text"
             class="form-control"
             formControlName="name"
             placeholder="Introduzca el nombre de la estación"
             [ngClass]="{'is-invalid' : submitted && formControls.name.errors}">
      <small class="invalid-feedback">Campo obligatorio</small>
    </div>

    <div class="mt-1 w-100 d-flex justify-content-center">
      <app-station-map [marker]="marker" (addressEmitter)="assignAddress($event)"></app-station-map>
    </div>
    <div class="mt-1">
      <label>Dirección: {{stationForm.value.address ?? 'Sin seleccionar'}}</label>
    </div>

    <div class="mt-1">
      <label>Información adicional sobre la estación</label>
      <textarea
        class="form-control"
        formControlName="aditionalInfo"
        placeholder="Ejemplo: A un lado de la tienda Soriana o frente a la central de autobuses">
      </textarea>
    </div>

    <div class="mt-1">
      <app-upload-file-storage
        [routeFile]="routeFile"
        [showPreview]="true"
        (isPreview)="isPreview = $event"
        label="Foto de la estación"
        controlName="imageUrl"
        extension="png">
      </app-upload-file-storage>
    </div>

    <div class="w-100 d-flex justify-content-center">
      <img [src]="station.imageUrl" class="w-50" alt="user photo" *ngIf="!isPreview && !!station.imageUrl">
    </div>

    <div class="mt-1 d-flex flex-row justify-content-end">
      <button [disabled]="loading" (click)="submit()" class="btn btn-primary btn-block" tabindex="4" rippleEffect>
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Guardar
      </button>
    </div>
  </form>
</div>
