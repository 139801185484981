<div class="p-2">
  <h3 *ngIf="!isVehicle">{{driver.name}}</h3>
  <h3 *ngIf="isVehicle">{{vehicle.name}}</h3>

  <h4 class="mt-4">Seleccione el horario:</h4>

  <app-datatable [rows$]="schedules$"
                 [config]="config"
                 (rowSelected)="handleScheduleSelected($event)">
    <app-datatable-column title="Horario">
      <ng-template let-row
                   #column>
        {{row.name}}
      </ng-template>
    </app-datatable-column>
  </app-datatable>
</div>
