<app-datatable (rowSelected)="openEditVehicleModal($event)" [config]="datatableConfig" [rows$]="vehicles$">

  <app-datatable-column title="Matrícula">
    <ng-template let-row #column>
      {{row.carRegistration}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Marca">
    <ng-template let-row #column>
      {{row.unitID}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Marca">
    <ng-template let-row #column>
      {{row.brand}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Modelo">
    <ng-template let-row #column>
      {{row.model}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Año">
    <ng-template let-row #column>
      {{row.yearModel}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Cupos">
    <ng-template let-row #column>
      {{row.spots}}
    </ng-template>
  </app-datatable-column>

  <app-data-table-option>
    <ng-template let-row #option>
      <div class="d-flex flex-row">
        <button class="btn btn-success btn-sm pl-a-half pr-a-half mr-1"
                ngbTooltip="Editar"
                (click)="openEditVehicleModal(row)">
          <i class="fa fa-edit fa-lg"></i>
        </button>

        <button class="btn btn-danger btn-sm pl-a-half pr-a-half"
                ngbTooltip="Eliminar"
                (click)="delete(row)">
          <i class="fa fa-trash fa-lg"></i>
        </button>
      </div>
    </ng-template>
  </app-data-table-option>

</app-datatable>
<app-add-float-button (clickEmmiter)="openVehicleModal()"></app-add-float-button>
