<div
  id="map-preview"
  class="map"
>
  <google-map
    [options]="options"
    width="100%"
    height="100%"
    #map
  >
    <map-marker
      #marker="mapMarker"
      *ngFor="let driver of driversMarkers"
      [position]="driver.position"
      (mapClick)="openInfoWindow(marker, driver)"
    ></map-marker>

    <map-info-window>
      <div
        class="d-flex p-1"
        *ngIf="markerSelected"
      >
        <div class="ml-1">
          <h5>{{markerSelected.driver?.name}} {{markerSelected.driver?.lastName}}</h5>
          <p>{{markerSelected.driver.phoneNumber}}</p>

          <h5 class="mt-1">Última actualización</h5>
          <p>{{markerSelected.lastUpdate | date: 'medium'}}</p>
        </div>
      </div>
    </map-info-window>
  </google-map>
</div>
