import { Component, ContentChild, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { DefaultColumns } from "./types";

@Component({
  selector: 'app-gantt',
  templateUrl: './gantt.component.html',
  styleUrls: ['./gantt.component.scss']
})
export class GanttComponent implements OnInit, OnChanges {
  @Input() columns: any[] = DefaultColumns;
  @Input() items = [];
  @Input() options = {
    columnWidth: 50,
    fixedColumnMinWidth: 100,
    fixedColumnMaxWidth: 100,
    fixedColumnGroupMinWidth: 150,
    fixedColumnGroupMaxWidth: 150,
  };
  @ContentChild('fixedColumn') fixedColumnTemplate;
  @ContentChild('fixedColumnGroup') fixedColumnGroupTemplate;
  @Output() onGroupItemClick = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.columns = [{
      isFixed: true,
      name: ''
    }, { isColumnGroup: true }, ...this.columns.filter(i => !i.isFixed && !i.isColumnGroup)]
    this.items = [{ isHeader: true }, ...this.items.filter(i => !i.isHeader)]
  }

  isActive(column: any, group: any) {
    return column.name.split(':')[0] >= group.startHour.split(':')[0] &&
           column.name.split(':')[0] <= group.endHour.split(':')[0]
  }

  isFirst(column: any, group: any) {
    return column.name.split(':')[0] === group.startHour.split(':')[0]
  }

  isLast(column: any, group: any) {
    return column.name.split(':')[0] === group.endHour.split(':')[0]
  }

  handleGroupItemClick(item: any, group: any) {
    this.onGroupItemClick.emit({ item, group })
  }
}
