<div class="p-2">
  <h3>Chofer actual: {{driver.name}} {{driver.lastName}}</h3>

  <h4 class="mt-4">Seleccionar nuevo chofer</h4>

  <app-datatable [rows$]="drivers$"
                 [config]="config"
                 (rowSelected)="handleSelectDriver($event)">
    <app-datatable-column title="Chofer">
      <ng-template let-row
                   #column>
        {{row.name + ' ' + row.lastName}}
      </ng-template>
    </app-datatable-column>
  </app-datatable>
</div>
