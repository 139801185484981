import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { ObjectService } from 'app/shared/services/object.service';

@Component({
  selector: 'ti-file',
  templateUrl: './ti-file.component.html',
  styleUrls: ['./ti-file.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TiFileComponent,
      multi: true
    }
  ]
})
export class TiFileComponent implements OnInit, ControlValueAccessor {
  @ViewChild('labelImport', { static: true }) labelImport: ElementRef;
  @Input() routeFile: string = `images/${new Date().getTime()}`;
  @Input() extension: string = 'png';
  @Input() showPreview: boolean = false;
  @Input() label: string = 'Archivo';
  @Input() errorLabel: string = 'Es obligatorio';
  @Input() formControl: any;
  @Output() isPreview: EventEmitter<boolean> = new EventEmitter();
  path: any;
  isInputValid: boolean = false;
  submitted: boolean = false;
  isImage: boolean = false;
  onChange: Function;

  constructor() {
  }

  ngOnInit(): void {
  }

  writeValue(value) {
    if (!ObjectService.isFile(value)) {
      this.path = value;
      this.isImage = true;
    }
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
  }

  onFileChange(event) {
    if (event.target.files) {
      this.isImage = event.target.files[0].type.includes('image');

      let reader = new FileReader();

      reader.onload = (event: ProgressEvent) => {
        this.path = (<FileReader>event.target).result;
      };

      reader.readAsDataURL(event.target.files[0]);

      this.onChange(event.target.files[0]);
    }
  }

}
