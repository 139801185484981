import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Validators } from "@angular/forms";
import { FlatpickrOptions } from "ng2-flatpickr";
import Spanish from "flatpickr/dist/l10n/es";
import { RouteModalComponent } from "../../route-modal.component";
import { WeekDays } from 'app/admin/pages/routes/types';

@Component({
  selector: 'app-general-info',
  templateUrl: './general-info.component.html',
  styleUrls: ['./general-info.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GeneralInfoComponent implements OnInit {
  weekDays: any[] = WeekDays;
  public DateRangeOptions: FlatpickrOptions = {
    altInput: true,
    locale: Spanish.es,
    mode: 'range',
    minDate: "today",
  }

  constructor(public routeModal: RouteModalComponent) {
  }

  ngOnInit(): void {
  }

  toggleIsCircuit() {
    if (this.routeModal.routeForm.value.isCircuit) return;
    this.routeModal.routeForm.patchValue({
      stations: this.routeModal.routeForm.value.stations.map(station => ({
        ...station,
        schedules: !!station.schedules && station.schedules.length ? [station.schedules[0]] : []
      }))
    })
  }

  toggleIsEventualValidators() {
    if (this.routeModal.routeForm.value.isEventual) return this.routeModal.routeForm.get('datesRange').setValidators([Validators.required])

    this.routeModal.routeForm.get('datesRange').clearValidators();
    this.routeModal.routeForm.get('datesRange').reset();
  }
}
