import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Route} from 'app/admin/interfaces/route';
import MapTypeId = google.maps.MapTypeId;
import {RouteModalComponent} from "../../route-modal.component";
import {Observable, timer} from 'rxjs';
import {MapDirectionsService, MapInfoWindow, MapMarker} from '@angular/google-maps';
import {map} from 'rxjs/operators';
import {Station} from 'app/admin/interfaces/station';

@Component({
  selector: 'app-map-preview',
  templateUrl: './map-preview.component.html',
  styleUrls: ['./map-preview.component.scss']
})
export class MapPreviewComponent implements OnInit, OnChanges {
  @Input() route: Route;
  @ViewChild('map') map: google.maps.Map;
  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;

  options: google.maps.MapOptions = {
    center: {lat: 29.085658, lng: -110.971709},
    zoom: 11,
    mapTypeId: MapTypeId.ROADMAP,
    disableDefaultUI: true
  };
  stations = []
  visibleMap: boolean;
  directionsResults$: Observable<google.maps.DirectionsResult | undefined>[];
  directionsRenderOptions: google.maps.DirectionsRendererOptions = {
    suppressMarkers: true,
    preserveViewport: true
  };
  lastDirectionsRenderOptions: google.maps.DirectionsRendererOptions = {
    suppressMarkers: true,
    preserveViewport: true,
    polylineOptions: {
      strokeColor: 'rgba(46,204,113,0.6)',
      strokeWeight: 7
    }
  };
  stationSelected: Station;

  constructor(public routeModal: RouteModalComponent,
              private mapDirectionsService: MapDirectionsService) {
  }

  ngOnInit(): void {
    this.routeModal.stepperElement.addEventListener('show.bs-stepper', (event: any) => {
      if (event.detail.indexStep == 3) {
        this.visibleMap = true;
        timer(100).subscribe(() => this.centralize())
        this.loadDirections();
      } else {
        this.visibleMap = false;
      }
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.route && changes.route.currentValue && changes.route.currentValue.stations) {
      this.stations = changes.route.currentValue.stations.map((station, index) => ({
        ...station,
        lat: station.latitude,
        lng: station.longitude,
        index: (index + 1).toString()
      }))
    }
  }

  getMarkerOptions(station: any): google.maps.MarkerOptions {
    return {
      draggable: false,
      label: {
        text: station.index,
        color: '#fff'
      }
    };
  }

  centralize() {
    const bounds = new google.maps.LatLngBounds();
    this.stations.forEach((marker) => {
      bounds.extend(new google.maps.LatLng(marker.lat, marker.lng))
    })
    this.map.fitBounds(bounds);
  }

  loadDirections() {
    this.directionsResults$ = [];

    for (let x = 0; x < this.stations.length - 1; x++) {
      this.directionsResults$.push(this.mapDirectionsService
        .route(this.getRequest(this.stations[x], this.stations[x + 1]))
        .pipe(
          map(response => response.result)
        ));
    }

    if (this.route.isCircuit) {
      this.directionsResults$.push(this.mapDirectionsService
        .route(this.getRequest(this.stations[this.stations.length - 1], this.stations[0]))
        .pipe(
          map(response => response.result)
        ));
    }
  }

  openInfoWindow(marker: MapMarker, station) {
    this.stationSelected = station;
    this.infoWindow.open(marker);
  }

  getRequest(origin, destination): google.maps.DirectionsRequest {
    return {
      destination: {
        lat: destination.lat,
        lng: destination.lng
      },
      origin: {
        lat: origin.lat,
        lng: origin.lng
      },
      travelMode: google.maps.TravelMode.DRIVING
    };
  }
}
