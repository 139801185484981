<div class="modal-header d-flex align-items-center flex-row">
  <h4 class="modal-title pull-left align-items-center">Chofer</h4>
  <button type="button" class="pull-right btn" (click)="modal.hide()">
    <i class="fa fa-times"></i>
  </button>
</div>
<div class="p-1">
  <form [formGroup]="driverForm">
    <div class="mt-1">
      <label>Nombre</label>
      <input type="text"
             class="form-control"
             formControlName="name"
             placeholder="Introduzca el nombre"
             [ngClass]="{'is-invalid' : submitted && formControls.name.errors}">
      <small class="invalid-feedback">Campo obligatorio</small>
    </div>
    <div class="mt-1">
      <label>Apellido</label>
      <input type="text" class="form-control" formControlName="lastName" placeholder="Introduzca el apellido"
             [ngClass]="{'is-invalid' : submitted && formControls.lastName.errors}">
      <small class="invalid-feedback">Campo obligatorio</small>
    </div>
    <div class="mt-1">
      <label>Correo</label>
      <input type="email" class="form-control" formControlName="email" placeholder="Introduzca el correo"
             [ngClass]="{'is-invalid' : submitted && formControls.email.errors}">
      <small class="invalid-feedback">Campo obligatorio</small>
    </div>
    <div class="mt-1">
      <label>Teléfono</label>
      <input type="number" class="form-control" formControlName="phoneNumber" placeholder="Introduzca el número de teléfono"
             [ngClass]="{'is-invalid' : submitted && formControls.phoneNumber.errors}">
      <small class="invalid-feedback">Campo obligatorio</small>
    </div>

    <div class="mt-1">
      <label>Fecha de nacimiento*</label>
      <input type="date"
             class="form-control"
             formControlName="birthdate"
             placeholder="Introduzca la fecha de nacimiento"
             [ngClass]="{'is-invalid' : submitted && formControls.birthdate.errors}">
      <small class="invalid-feedback">Campo obligatorio</small>
    </div>

    <div class="mt-1">
      <app-upload-file-storage
        [routeFile]="routeFile"
        [showPreview]="true"
        (isPreview)="isPreview = $event"
        label="Foto del chofer"
        controlName="imageUrl"
        extension="png">
      </app-upload-file-storage>
    </div>

    <div class="w-100 d-flex justify-content-center">
      <img [src]="driver.imageUrl" class="w-25" alt="user photo" *ngIf="!isPreview && !!driver.imageUrl">
    </div>

    <div class="mt-1 d-flex flex-row justify-content-end">
      <button [disabled]="loading" (click)="submit()" class="btn btn-primary btn-block" tabindex="4" rippleEffect>
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Guardar
      </button>
    </div>
  </form>
</div>
