<app-gantt
  [items]="items"
  [options]="ganttOptions"
  (onGroupItemClick)="handleGroupItemClick($event)">
  <ng-template #fixedColumn
               let-item>
    <div class="text-truncate cursor-pointer"
         (click)="handleDriverClick(item)">{{item.name}} {{item.lastName}}</div>
  </ng-template>

  <ng-template #fixedColumnGroup
               let-group>
    <span class="text-truncate">{{group?.data?.name}} {{group.startHour}}-{{group.endHour}}</span>
  </ng-template>
</app-gantt>
