import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {RouteModalComponent} from "../../route-modal.component";

@Component({
  selector: 'app-vehicle-info',
  templateUrl: './vehicle-info.component.html',
  styleUrls: ['./vehicle-info.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VehicleInfoComponent implements OnInit {

  constructor(public routeModal: RouteModalComponent) {
  }

  ngOnInit(): void {
  }

}
