<app-datatable
  (rowSelected)="openUserDetails($event)"
  [config]="datatableConfig"
  [rows$]="users$">

  <app-datatable-column title="Nombre">
    <ng-template let-row #column>
      {{row.name}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Celular">
    <ng-template let-row #column>
      {{row.phoneNumber}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Correo">
    <ng-template let-row #column>
      {{row.email}}
    </ng-template>
  </app-datatable-column>

</app-datatable>
