import {Component, ContentChild, OnInit, TemplateRef} from '@angular/core';

@Component({
  selector: 'app-data-table-option',
  template: '',
  styleUrls: []
})
export class DataTableOptionComponent implements OnInit {
  @ContentChild('option') template: TemplateRef<any>;

  constructor() {
  }

  ngOnInit(): void {
  }

}
