import { Routes } from '@angular/router';
import { VerticalLayoutComponent } from "../layout/vertical/vertical-layout.component";
import { DriversComponent } from "./pages/drivers/drivers.component";
import { VehiclesComponent } from "./pages/vehicles/vehicles.component";
import { StationsComponent } from "./pages/stations/stations.component";
import { RoutesComponent } from "./pages/routes/routes.component";
import { LogsComponent } from "./pages/logs/logs.component";
import { WelcomeSlidersComponent } from './pages/welcome-sliders/welcome-sliders.component';
import { AdminMenu } from "./admin-menu";
import { AssignsModule } from "./pages/assigns/assigns.module";
import { UsersComponent } from "./pages/users/users.component";
import { RealtimeMapComponent } from "./pages/realtime-map/realtime-map.component";

export const AdminRoutes: Routes = [
  {
    path: '',
    component: VerticalLayoutComponent,
    data: { menu: AdminMenu },
    children: [
      {
        path: 'users',
        component: UsersComponent
      },
      {
        path: 'drivers',
        component: DriversComponent
      },
      {
        path: 'vehicles',
        component: VehiclesComponent
      },
      {
        path: 'stations',
        component: StationsComponent
      },
      {
        path: 'routes',
        component: RoutesComponent
      },
      {
        path: 'assigns',
        loadChildren: () => AssignsModule
      },
      {
        path: 'logs',
        component: LogsComponent
      },
      {
        path: 'map',
        component: RealtimeMapComponent
      },
      {
        path: 'welcome-sliders',
        component: WelcomeSlidersComponent
      },
      {
        path: '**',
        redirectTo: 'drivers'
      }
    ]
  }
];
