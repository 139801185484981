import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {BsModalService} from "ngx-bootstrap/modal";
import {ToastService} from "../../../shared/services/toast.service";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {AlertService} from "../../../shared/services/alert.service";
import {AuthService} from "../../../shared/services/auth.service";
import {DriverService} from "../../services/driver.service";
import {Driver} from "../../interfaces/driver";
import {
  UploadFileStorageComponent
} from "../../../shared/template-components/upload-file-storage/upload-file-storage.component";
import moment from "moment";

@Component({
  selector: 'app-driver',
  templateUrl: './driver-modal.component.html',
  styleUrls: ['./driver-modal.component.scss']
})
export class DriverModalComponent implements OnInit {
  @ViewChild(UploadFileStorageComponent) filesStorage: UploadFileStorageComponent;
  driver: Driver = {key: ''} as Driver;
  driverForm: FormGroup
  submitted: boolean = false;
  loading: boolean = false;
  isEdit: boolean = false;
  routeFile: string;
  isPreview: boolean = false;

  constructor(public modal: BsModalService,
              private formBuilder: FormBuilder,
              private _driver: DriverService,
              private afs: AngularFirestore,
              private toastr: ToastService) {
    this.createDriverForm();
  }

  ngOnInit(): void {
    if (this.isEdit) {
      this.driverForm.patchValue({...this.driver, birthdate: moment(this.driver.birthdate).format('YYYY-MM-DD')});
      this.driverForm.controls['email'].disable();
    } else {
      this.driver.key = this.afs.createId();
    }
    this.routeFile = `drivers/${this.driver.key}/photo`;
  }

  createDriverForm() {
    this.driverForm = this.formBuilder.group({
      name: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: [null, [Validators.required]],
      birthdate: [],
      imageUrl: [],
      updatedAt: [new Date().getTime()],
      createdAt: [new Date().getTime()],
      trash: [false]
    })
  }

  get formControls() {
    return this.driverForm.controls;
  }

  async submit() {
    this.submitted = true;
    if (!this.driverForm.valid) return AlertService.toastError('Verifique sus datos');
    if (!await AlertService.confirm('Estás seguro de que deseas guardar este chofer?')) return;

    this.loading = true;

    if (!this.isEdit) await this.createAccount();

    if (this.isPreview) {
      const url = await this.filesStorage.uploadDocument();
      if (!!url) this.driverForm.patchValue({[this.filesStorage.controlName]: url});
    }

    this.driverForm.patchValue({birthdate: moment(this.driverForm.value.birthdate).toDate().getTime()})

    await this._driver.set(this.driver.key, this.driverForm.value);
    this.toastr.showSuccess('Los datos se guardaron correctamente');
    this.modal.hide();
  }

  private async createAccount() {
    const resp = await this._driver.createAccount(this.driverForm.get('email').value);
    if (!resp.ok) {
      await AlertService.error('Error', AuthService.getMessageError(resp.err.code));
      throw new Error();
    } else {
      this.driver.key = resp.data.uid;
    }
  }
}
