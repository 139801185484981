import {Component, OnInit} from '@angular/core';
import {Station} from "../../interfaces/station";
import {BsModalRef} from "ngx-bootstrap/modal";
import {Schedule} from "../../interfaces/schedule";

@Component({
  selector: 'app-schedule-station-modal',
  templateUrl: './schedule-station-modal.component.html',
  styleUrls: ['./schedule-station-modal.component.scss']
})
export class ScheduleStationModalComponent implements OnInit {
  currentSchedule: any;
  hourSelected: any = null;

  constructor(public modal: BsModalRef) {
  }

  ngOnInit(): void {
    if (!this.currentSchedule.hours) this.currentSchedule.hours = []
  }

  addScheduleToStation() {
    if (!this.hourSelected) return;

    this.currentSchedule.hours = [
      ...this.currentSchedule.hours,
      this.hourSelected
    ]

    this.hourSelected = null;
  }

  deleteSchedule(index: number) {
    this.currentSchedule.hours.splice(index, 1)
  }
}
