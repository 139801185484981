<ng-container *ngIf="rows$ | async as rows; else loaded">
  <ng-container *ngIf="rows.length > 0; else notFound">
    <div class="card">
      <div class="card-header" *ngIf="!!config.title || config.hasSearch">
        <h4 class="card-title">{{config.title}}</h4>
        <div class="d-flex flex-row justify-content-between">
          <h4 class="card-title mr-2">Se encontraron {{rows.length}} resultados</h4>
        </div>
        <div class="heading-elements">
          <ul class="list-inline mb-0">
            <li class="mr-1" *ngIf="printSmall">
              <button class="btn btn-warning" (click)="printList()" app-tooltip="Imprimir lista">
                <i class="la la-print"></i>
              </button>
            </li>
            <li class="mr-1" *ngIf="!!config.hasExportButton">
              <button class="btn btn-success btn-sm" (click)="exportToExcel(rows)" app-tooltip="Exportar lista">
                <i class="fa fa-file-excel-o"></i>
              </button>
            </li>
            <li *ngIf="config.hasSearch">
              <div class=" position-relative has-icon-left">
                <input type="text" class="form-control" id="iconLeft4" placeholder="Buscar" [(ngModel)]="search">
                <div class="form-control-position">
                  <i class="feather ft-search"></i>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-content" [ngClass]="{'mt-3':!!config.title}" #componentID>
        <div class="table-responsive" fxFlex="auto" [perfectScrollbar]="config">
          <table class="table border_bottom table-hover">
            <thead>
            <tr>
              <th scope="col" class="border-top-0"
                  [ngClass]="{'d-none' : !((column.isVisiblePrint || !isPrinting ) && (!column.onlyPrint || isPrinting))}"
                  *ngFor="let column of columns"><span
                *ngIf="(column.isVisiblePrint || !isPrinting ) && (!column.onlyPrint || isPrinting)">{{column.title}}</span>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr class="cursor-pointer position-relative"
                *ngFor="let row of rows | filter: search : (!!config.filtersFields ? config.filtersFields : []) | sort: config.sortByField : orderType; index as index; trackBy: trackByKey"
                (mouseover)="row.isHover = true"
                (mouseleave)="row.isHover = false">
              <td class="text-truncate"
                  [ngClass]="{'text-small' : printSmall && isPrinting, 'd-none' : !((column.isVisiblePrint || !isPrinting ) && (!column.onlyPrint || isPrinting))}"
                  *ngFor="let column of columns"
                  (click)="selectRow(column.isClickable, row)">
                <ng-container
                  [ngTemplateOutlet]="column.template"
                  [ngTemplateOutletContext]="{ $implicit: row, index: index }"
                  *ngIf="(column.isVisiblePrint || !isPrinting ) && (!column.onlyPrint || isPrinting)">
                </ng-container>
              </td>
              <div class="options d-flex justify-content-end " *ngIf="row.isHover">
                <div class="option d-flex align-items-center" *ngFor="let option of options">
                  <ng-container
                    [ngTemplateOutlet]="option.template"
                    [ngTemplateOutletContext]="{ $implicit: row, index: index }">
                  </ng-container>
                </div>
              </div>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #notFound>
    <app-not-found size="medium" [title]="config.notFoundText"></app-not-found>
  </ng-template>

</ng-container>

<ng-template #loaded>
  <div class="loader-wrapper">
    <div class="loader-container">
      <div class="ball-clip-rotate loader-primary">
        <div></div>
      </div>
    </div>
  </div>
</ng-template>
