import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { BsModalService } from "ngx-bootstrap/modal";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { ToastService } from "../../../../../shared/services/toast.service";
import { AlertService } from "../../../../../shared/services/alert.service";
import { Route } from 'app/admin/interfaces/route';
import { RouteService } from "../../../../services/route.service";
import { DriverService } from "../../../../services/driver.service";
import { StationService } from "../../../../services/station.service";
import { VehicleService } from 'app/admin/services/vehicle.service';
import { Station } from "../../../../interfaces/station";
import { first } from "rxjs/operators";
import Stepper from 'bs-stepper';
import { Driver } from "../../../../interfaces/driver";
import { Vehicle } from "../../../../interfaces/vehicle";
import moment from "moment";
import { ScheduleService } from "../../../../services/schedule.service";
import { Schedule } from 'app/admin/interfaces/schedule';
import { Subscription } from "rxjs";
import { WeekDaysLabel } from "../../../../labels/week-days.label";

@Component({
  selector: 'app-route-modal',
  templateUrl: './route-modal.component.html',
  styleUrls: ['./route-modal.component.scss']
})
export class RouteModalComponent implements OnInit, OnDestroy {
  route: Route = { key: '' } as Route;
  routeForm: FormGroup;
  submitted: boolean = false;
  loading: boolean = false;
  isEdit: boolean = false;
  stations: Station[] = [];
  drivers: Driver[] = [];
  vehicles: Vehicle[] = [];
  verticalWizardStepper: Stepper;
  stepperElement: Element;
  schedules: Schedule[] = [];
  scheduleSubscription: Subscription = new Subscription();

  constructor(public modal: BsModalService,
              private formBuilder: FormBuilder,
              private _route: RouteService,
              private afs: AngularFirestore,
              private toastr: ToastService,
              private _station: StationService,
              private _driver: DriverService,
              private _vehicle: VehicleService,
              private _schedules: ScheduleService) {
    this.createRouteForm();
  }

  ngOnDestroy(): void {
    this.scheduleSubscription.unsubscribe();
  }

  async ngOnInit() {
    this.stepperElement = document.querySelector('#stepper2');
    this.verticalWizardStepper = new Stepper(document.querySelector('#stepper2'), {
      linear: false,
      animation: true
    });

    this.drivers = (await this._driver.getAll().pipe(first()).toPromise())
      .map(drive => ({
        ...drive,
        fullName: `${drive.name} ${drive.lastName}`
      }));
    this.vehicles = await this._vehicle.getAll().pipe(first()).toPromise();
    this.stations = await this._station.getAll().pipe(first()).toPromise();

    if (this.isEdit) {
      this.assignSchedules();

      return this.routeForm.patchValue({
        ...this.route,
        stations: this.getStationsSelected(),
        datesRange: this.route.isEventual
          ? [moment(this.route.datesRange.from).format('YYYY-MM-DD'), moment(this.route.datesRange.to).format('YYYY-MM-DD')]
          : [],
        weekDays: this.route.weekDays.map(weekDay => ({
          label: WeekDaysLabel[weekDay],
          value: weekDay
        }))
      })
    }

    this.route.key = this.afs.createId();
  }

  previousWizard() {
    this.verticalWizardStepper.previous();
  }

  createRouteForm() {
    this.routeForm = this.formBuilder.group({
      name: [null, Validators.required],
      stations: [null, Validators.required],
      schedules: [null],
      weekDays: [null, Validators.required],
      datesRange: [null],
      isEventual: [null],
      isCircuit: [false],
      trash: [false],
      createdAt: [new Date().getTime()],
      updatedAt: [new Date().getTime()],
    });
  }

  assignSchedules() {
    this.scheduleSubscription = this._schedules.getAllByRoute(this.route.key).subscribe(schedules => {
      if (schedules.length) {
        this.schedules = schedules.map((currentSchedule: any) => ({
          ...currentSchedule,
          schedule: currentSchedule.schedule.map(schedule => ({
            ...schedule,
            station: this.stations.find(station => station.key === schedule.station.id)
          }))
        }));
      }
    });
  }

  getStationsSelected() {
    return this.route.stations.map(station => {
      return {
        ...this.stations.find(currentStation => currentStation.key === station.station.id),
        price: station.price
      }
    })
  }

  get formControls() {
    return this.routeForm.controls;
  }

  decorateRoute() {
    const { isEventual, datesRange } = this.routeForm.value;
    this.routeForm.patchValue({
      stations: this.routeForm.value.stations.map(station => ({
        station: this._station.getRef(station.key),
        price: station.price
      })),
      datesRange: isEventual
        ? {
          from: new Date(datesRange[0]).getTime(),
          to: new Date(datesRange[1]).getTime()
        } : null
    })
  }

  async submit() {
    this.submitted = true;
    if (!this.routeForm.valid) return AlertService.toastError('Verifique sus datos');
    if (!await AlertService.confirm('¿Estás seguro de que deseas guardar esta ruta?')) return;

    this.loading = true;

    this.decorateRoute();

    await this._route.set(this.route.key, {
      ...this.routeForm.value,
      weekDays: this.routeForm.value.weekDays.map(weekDay => weekDay.value),
      updatedAt: new Date().getTime()
    });
    await this._schedules.createSchedules(this.route.key, this.schedules);

    this.toastr.showSuccess('Los datos se guardaron correctamente');
    this.loading = false;
    this.modal.hide();
  }

  verticalWizardNext() {
    this.verticalWizardStepper.next();
  }

  getFormControl(name: string): FormControl {
    return this.formControls[name] as FormControl;
  }
}
