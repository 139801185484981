import { WeekDaysLabel } from "../../labels/week-days.label";
import { WeekDays as WeekDaysEnum } from "../../enums/week-days";

export const WeekDays: any[] = [
  {
    label: WeekDaysLabel[WeekDaysEnum.MONDAY],
    value: WeekDaysEnum.MONDAY
  },
  {
    label: WeekDaysLabel[WeekDaysEnum.TUESDAY],
    value: WeekDaysEnum.TUESDAY
  },
  {
    label: WeekDaysLabel[WeekDaysEnum.WEDNESDAY],
    value: WeekDaysEnum.WEDNESDAY
  },
  {
    label: WeekDaysLabel[WeekDaysEnum.THURSDAY],
    value: WeekDaysEnum.THURSDAY
  },
  {
    label: WeekDaysLabel[WeekDaysEnum.FRIDAY],
    value: WeekDaysEnum.FRIDAY
  },
  {
    label: WeekDaysLabel[WeekDaysEnum.SATURDAY],
    value: WeekDaysEnum.SATURDAY
  },
  {
    label: WeekDaysLabel[WeekDaysEnum.SUNDAY],
    value: WeekDaysEnum.SUNDAY
  },
];
