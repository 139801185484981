import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {RouteModalComponent} from "../../route-modal.component";

@Component({
  selector: 'app-vertical-stepper',
  templateUrl: './vertical-stepper.component.html',
  styleUrls: ['./vertical-stepper.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VerticalStepperComponent implements OnInit {

  constructor(public routeModal: RouteModalComponent) {
  }

  ngOnInit(): void {
  }

}
