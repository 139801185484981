import { Injectable } from '@angular/core';
import { FirebaseDataService } from "../../shared/services/firebase-data.service";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { StationService } from "./station.service";
import { RouteService } from "./route.service";
import { DriverService } from "./driver.service";
import { VehicleService } from "./vehicle.service";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ScheduleService extends FirebaseDataService {

  constructor(private afs: AngularFirestore,
              private http: HttpClient,
              private _station: StationService,
              private _route: RouteService,
              private _driver: DriverService,
              private _vehicle: VehicleService) {
    super(afs, null);
  }

  getAllByRoute(routeKey: string): Observable<any[]> {
    return this.colWithIds$(`routes/${routeKey}/schedules`, ref => ref.where('trash', '==', false))
  }

  getAll(): Observable<any[]> {
    return this.colGroup('schedules', ref => ref
      .where('trash', '==', false))
      .snapshotChanges()
      .pipe(
        map(docs => docs.map(a => {
          const data = a.payload.doc.data();
          data['key'] = a.payload.doc.id;
          data['route'] = this.getReference(`routes/${a.payload.doc.ref.parent.parent.id}`);
          return data;
        }))
      )
  }

  get(routeKey: string, scheduleKey: string): Observable<any> {
    return this.docWithId$(`routes/${routeKey}/schedules/${scheduleKey}`);
  }

  updateSchedule(routeKey: string, scheduleKey: string, schedule: any) {
    delete schedule.key;
    return this.afs.doc(`routes/${routeKey}/schedules/${scheduleKey}`).update({
      ...schedule,
      updatedAt: new Date().getTime()
    });
  }

  delete(routeKey: string, scheduleKey: string): Promise<void> {
    return this.afs.doc(`routes/${routeKey}/schedules/${scheduleKey}`).update({
      trash: true,
      updatedAt: new Date().getTime()
    });
  }

  setSchedule(routeKey: string, scheduleKey: string, schedule: any) {
    return this.afs.doc(`routes/${routeKey}/schedules/${scheduleKey}`).set(schedule, { merge: true });
  }

  add(routeKey: string, schedule: any) {
    return this.afs.collection(`routes/${routeKey}/schedules`).add(schedule);
  }

  async createSchedules(routeKey: string, schedules: any[]) {
    let currentSchedules = schedules.map((currentSchedule: any) => ({
      ...currentSchedule,
      schedule: currentSchedule.schedule.map((schedule: any) => ({
        ...schedule,
        station: this._station.getRef(schedule.station.key)
      }))
    }))

    for (let schedule of currentSchedules) {
      await this.setSchedule(routeKey, schedule.key, schedule)
    }
  }
}
