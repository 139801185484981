import {Component, OnInit, ViewChild} from '@angular/core';
import {
  UploadFileStorageComponent
} from "../../../shared/template-components/upload-file-storage/upload-file-storage.component";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {BsModalService} from "ngx-bootstrap/modal";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {ToastService} from "../../../shared/services/toast.service";
import {AlertService} from "../../../shared/services/alert.service";
import {Vehicle} from "../../interfaces/vehicle";
import {VehicleService} from "../../services/vehicle.service";

@Component({
  selector: 'app-vehicle-modal',
  templateUrl: './vehicle-modal.component.html',
  styleUrls: ['./vehicle-modal.component.scss']
})
export class VehicleModalComponent implements OnInit {
  @ViewChild(UploadFileStorageComponent) filesStorage: UploadFileStorageComponent;
  vehicle: Vehicle = {key: ''} as Vehicle;
  vehicleForm: FormGroup
  submitted: boolean = false;
  loading: boolean = false;
  isEdit: boolean = false;
  routeFile: string;
  isPreview: boolean = false;

  constructor(public modal: BsModalService,
              private formBuilder: FormBuilder,
              private _vehicle: VehicleService,
              private afs: AngularFirestore,
              private toastr: ToastService) {
    this.createVehicleForm();
  }

  ngOnInit(): void {
    if (this.isEdit) {
      this.vehicleForm.patchValue({...this.vehicle});
    } else {
      this.vehicle.key = this.afs.createId();
    }
    this.routeFile = `vehicles/${this.vehicle.key}/photo`;
  }

  createVehicleForm() {
    this.vehicleForm = this.formBuilder.group({
      brand: [null, [Validators.required]],
      model: [null, [Validators.required]],
      spots: [null, [Validators.required]],
      unitID: [null, [Validators.required]],
      yearModel: [null, [Validators.required]],
      carRegistration: [null, [Validators.required]],
      details: [null, [Validators.required]],
      imageUrl: [null],
      updatedAt: [new Date().getTime()],
      createdAt: [new Date().getTime()],
      trash: [false]
    })
  }

  get formControls() {
    return this.vehicleForm.controls;
  }

  async submit() {
    this.submitted = true;
    if (!this.vehicleForm.valid) return AlertService.toastError('Verifique sus datos');
    if (!await AlertService.confirm('Estás seguro de que deseas guardar este vehículo?')) return;

    this.loading = true;

    if (this.isPreview) {
      const url = await this.filesStorage.uploadDocument();
      if (!!url) this.vehicleForm.patchValue({[this.filesStorage.controlName]: url});
    }

    await this._vehicle.set(this.vehicle.key, this.vehicleForm.value);
    this.toastr.showSuccess('Los datos se guardaron correctamente');
    this.modal.hide();
  }
}
