import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SliderService } from 'app/admin/services/slider.service';
import { ISlider } from "../../interfaces/slider";
import { AlertService } from "../../../shared/services/alert.service";
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
  selector: 'app-slider-modal',
  templateUrl: './slider-modal.component.html',
  styleUrls: ['./slider-modal.component.scss']
})
export class SliderModalComponent implements OnInit {
  slider: ISlider;
  submitted: boolean;
  sliderForm: FormGroup;
  isEdit: boolean;

  constructor(private fb: FormBuilder,
              private _slider: SliderService,
              private modalRef: BsModalRef) {
    this.sliderForm = this.fb.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      image: ['', Validators.required],
      position: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    if (this.slider) {
      this.sliderForm.patchValue(this.slider);
      this.isEdit = true;
    }
  }

  async submit() {
    this.submitted = true;

    if (this.sliderForm.invalid) return AlertService.toastError('Todos los campos son obligatorios');

    this.isEdit ? await this.update() : await this.create();

    AlertService.toastSuccess('Slider guardado correctamente');
    this.modalRef.hide();
  }

  async create() {
    await this._slider.create(this.sliderForm.value);
  }

  async update() {
    await this._slider.updateSlider(this.slider.key, this.sliderForm.value);
  }

}
