import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";
import { AdminRoutes } from "./admin-routing.module";
import { DriversComponent } from './pages/drivers/drivers.component';
import { SharedModule } from "../shared/shared.module";
import { DriverModalComponent } from './modals/driver-modal/driver-modal.component';
import { CoreDirectivesModule } from "../../@core/directives/directives";
import { VehiclesComponent } from './pages/vehicles/vehicles.component';
import { VehicleModalComponent } from './modals/vehicle-modal/vehicle-modal.component';
import { StationsComponent } from './pages/stations/stations.component';
import { StationModalComponent } from './modals/station-modal/station-modal.component';
import { StationMapComponent } from './components/station-map/station-map.component';
import { GoogleMapsModule } from "@angular/google-maps";
import { RoutesComponent } from './pages/routes/routes.component';
import { RouteModalComponent } from './pages/routes/modals/route-modal/route-modal.component';
import { ContentHeaderModule } from "../layout/components/content-header/content-header.module";
import { CardSnippetModule } from "../../@core/components/card-snippet/card-snippet.module";
import { Ng2FlatpickrModule } from "ng2-flatpickr";
import { NgxViewerModule } from "ngx-viewer";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { ScheduleStationModalComponent } from './modals/schedule-station-modal/schedule-station-modal.component';
import { GeneralInfoComponent } from './pages/routes/modals/route-modal/components/general-info/general-info.component';
import { VehicleInfoComponent } from './pages/routes/modals/route-modal/components/vehicle-info/vehicle-info.component';
import { MapPreviewComponent } from './pages/routes/modals/route-modal/components/map-preview/map-preview.component';
import {
  StationsRouteModalComponent
} from "./pages/routes/modals/route-modal/components/stations/stations-route-modal.component";
import {
  VerticalStepperComponent
} from './pages/routes/modals/route-modal/components/vertical-stepper/vertical-stepper.component';
import {
  SchedulesStationsComponent
} from './pages/routes/modals/route-modal/components/schedules-stations/schedules-stations.component';
import {
  SchedulesStationsModalComponent
} from './pages/routes/modals/route-modal/components/schedules-stations-modal/schedules-stations-modal.component';
import { SettingsComponent } from './modals/settings/settings.component';
import { LogsComponent } from './pages/logs/logs.component';
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { LogComponent } from './modals/log/log.component';
import { WelcomeSlidersComponent } from './pages/welcome-sliders/welcome-sliders.component';
import { SliderModalComponent } from './modals/slider-modal/slider-modal.component';
import { AssignsModule } from "./pages/assigns/assigns.module";
import { UsersComponent } from './pages/users/users.component';
import { RealtimeMapComponent } from './pages/realtime-map/realtime-map.component';

@NgModule({
  declarations: [
    DriversComponent,
    DriverModalComponent,
    VehiclesComponent,
    VehicleModalComponent,
    StationsComponent,
    StationModalComponent,
    StationMapComponent,
    RoutesComponent,
    RouteModalComponent,
    ScheduleStationModalComponent,
    GeneralInfoComponent,
    VehicleInfoComponent,
    MapPreviewComponent,
    StationsRouteModalComponent,
    VerticalStepperComponent,
    SchedulesStationsComponent,
    SchedulesStationsModalComponent,
    SettingsComponent,
    LogsComponent,
    LogComponent,
    WelcomeSlidersComponent,
    SliderModalComponent,
    UsersComponent,
    RealtimeMapComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(AdminRoutes),
    SharedModule,
    CoreDirectivesModule,
    GoogleMapsModule,
    ContentHeaderModule,
    CardSnippetModule,
    Ng2FlatpickrModule,
    NgxViewerModule,
    DragDropModule,
    TooltipModule,
    AssignsModule
  ]
})
export class AdminModule { }
