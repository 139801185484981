<div class="bs-stepper-header">
  <div class="step"
       data-target="#general-info">
    <button type="button"
            class="step-trigger">
      <span class="bs-stepper-box">1</span>
      <span class="bs-stepper-label">
                <span class="bs-stepper-title">Información general</span>
                <span class="bs-stepper-subtitle">Elegir nombre, días de la semana etc.</span>
              </span>
    </button>
  </div>
  <div class="step"
       data-target="#stations">
    <button type="button"
            class="step-trigger"
            [disabled]="routeModal.formControls.name.errors || routeModal.formControls.weekDays.errors">
      <span class="bs-stepper-box">2</span>
      <span class="bs-stepper-label">
                <span class="bs-stepper-title">Estaciones</span>
                <span class="bs-stepper-subtitle">Elegir estaciones de la ruta</span>
              </span>
    </button>
  </div>

  <div class="step"
       data-target="#schedules">
    <button type="button"
            class="step-trigger"
            [disabled]="routeModal.formControls.stations.errors">
      <span class="bs-stepper-box">3</span>
      <span class="bs-stepper-label">
                <span class="bs-stepper-title">Horarios</span>
                <span class="bs-stepper-subtitle">Creación de horarios</span>
              </span>
    </button>
  </div>

  <div class="step"
       data-target="#map-preview">
    <button type="button"
            class="step-trigger">
      <span class="bs-stepper-box">4</span>
      <span class="bs-stepper-label">
                <span class="bs-stepper-title">Vista previa</span>
                <span class="bs-stepper-subtitle"></span>
              </span>
    </button>
  </div>
</div>
