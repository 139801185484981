import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssignsComponent } from "./assigns.component";
import { GanttComponent } from "./components/gantt/gantt.component";
import { RouterModule } from "@angular/router";
import { SharedModule } from "../../../shared/shared.module";
import { DriverComponent } from './components/driver/driver.component';
import { DriverSelectionComponent } from './modals/driver-selection/driver-selection.component';
import { ScheduleSelectionComponent } from './modals/schedule-selection/schedule-selection.component';
import { VehicleComponent } from './components/vehicle/vehicle.component';
import { VehicleSelectionComponent } from './modals/vehicle-selection/vehicle-selection.component';

@NgModule({
  declarations: [
    AssignsComponent,
    GanttComponent,
    DriverComponent,
    DriverSelectionComponent,
    ScheduleSelectionComponent,
    VehicleComponent,
    VehicleSelectionComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: 'gantt',
        component: AssignsComponent
      }
    ]),
    SharedModule
  ]
})
export class AssignsModule {
}
