import {Routes} from '@angular/router';
import {VerticalLayoutComponent} from "../layout/vertical/vertical-layout.component";
import {LoginComponent} from "./pages/login/login.component";


export const AuthRoutes: Routes = [
  {
    path: '',
    component: VerticalLayoutComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: '**',
        redirectTo: 'login'
      },
    ]
  }
]
