import { Injectable } from '@angular/core';
import { Driver } from "../../../interfaces/driver";
import { FirebaseDataService } from "../../../../shared/services/firebase-data.service";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { Schedule } from "../../../interfaces/schedule";
import { ReplaySubject } from "rxjs";
import { Vehicle } from "../../../interfaces/vehicle";
import { AlertService } from "../../../../shared/services/alert.service";

@Injectable({
  providedIn: 'root'
})
export class AssignService extends FirebaseDataService {
  drivers$: ReplaySubject<Driver[]> = new ReplaySubject<Driver[]>();
  schedules$: ReplaySubject<Schedule[]> = new ReplaySubject<Schedule[]>();
  vehicles$: ReplaySubject<Vehicle[]> = new ReplaySubject<Vehicle[]>();

  constructor(private _db: AngularFirestore,
    private _storage: AngularFireStorage) {
    super(_db, _storage);
  }

  decorateHoursGroup({
    decoratedSchedules,
    vehicle,
    driver
  }: any) {
    return decoratedSchedules.map((schedule, index) => {
      const startHour = schedule.allHours[0];
      const endHour = schedule.allHours[schedule.allHours.length - 1];

      const nextSchedule = decoratedSchedules[index + 1];
      const prevSchedule = decoratedSchedules[index - 1];

      const hasNextCollision = endHour > nextSchedule?.allHours[0];
      const hasPreviosCollision = startHour < prevSchedule?.allHours[prevSchedule.allHours.length - 1];

      if (hasNextCollision) {
        AlertService.toastError(`El ${vehicle ? 'vehículo' : 'conductor'} ${vehicle ? vehicle.unitID : `${driver.name} ${driver?.lastName}`} tiene un choque de horario entre las ${endHour} y las ${nextSchedule?.allHours[0]}`);
      }

      if (hasPreviosCollision) {
        AlertService.toastError(`El ${vehicle ? 'vehículo' : 'conductor'} ${vehicle ? vehicle.unitID : `${driver.name} ${driver?.lastName}`} tiene un choque de horario entre las ${startHour} y las ${prevSchedule?.allHours[prevSchedule.allHours.length - 1]} `);
      }

      return ({
        data: schedule,
        startHour,
        endHour,
        bgColor: hasNextCollision || hasPreviosCollision ? '#f44336' : '#4caf50'
      });
    });
  }
}
