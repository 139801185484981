import {Component, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TiFormComponent} from '../ti-form/ti-form.component';

@Component({
  selector: 'ti-input',
  templateUrl: './ti-input.component.html',
  styleUrls: ['./ti-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TiInputComponent,
      multi: true
    }
  ]
})
export class TiInputComponent implements OnInit, ControlValueAccessor {
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() type: string = 'text';
  @Input() name: any;
  @Input() classes: string;
  @Input() bindLabel: string;
  @Input() multiple: boolean;
  @Input() disabled: boolean;
  @Input() items: any[] = [];
  @Input() routeFile: string;

  get formControl() {
    return this.form.controls[this.name];
  }

  constructor(public form: TiFormComponent) {
  }

  ngOnInit(): void {
    if (this.disabled) this.formControl.disable();
  }

  writeValue(value) {
  }

  registerOnChange(fn) {
  }

  registerOnTouched(fn) {
  }

  getMessageError(errors) {
    if (!errors) return '';

    switch (Object.keys(errors)[0]) {
      case 'email':
        return 'Email inválido';

      default :
        return 'Campo obligatorio';
    }
  }
}
