export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDf3Pfe2G4yiTGu_bDBT5dFSqMEyBFMNj8",
    authDomain: "vetenvan-dev.firebaseapp.com",
    databaseURL: "https://vetenvan-dev-default-rtdb.firebaseio.com",
    projectId: "vetenvan-dev",
    storageBucket: "vetenvan-dev.appspot.com",
    messagingSenderId: "618481755647",
    appId: "1:618481755647:web:41d0e06a691b80ebf833ca",
    measurementId: "G-6PBMYBHYGW"
  },
  hmr: false,
  useEmulators: false,
  apiUrl: "https://us-central1-vetenvan-dev.cloudfunctions.net/api",
  fingerprintUrl: 'ws://localhost:2015',
  version: '0.0.1'
};
