import {Component, ContentChild, Input, OnInit, TemplateRef} from '@angular/core';

@Component({
  selector: 'app-datatable-column',
  template: ``,
  styleUrls: []
})
export class DataTableColumnComponent implements OnInit {
  @Input() title: string;
  @Input() field: string;
  @Input() public isClickable: boolean = true;
  @Input() public isVisiblePrint: boolean = true;
  @Input() public onlyPrint: boolean = false;
  @ContentChild('column') template: TemplateRef<any>;

  constructor() {
  }

  ngOnInit(): void {
  }

}
