import {Injectable} from '@angular/core';
import {FirebaseDataService} from "../../shared/services/firebase-data.service";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {Observable} from "rxjs";
import {Station} from "../interfaces/station";
import {Route} from "../interfaces/route";
import {take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RouteService {
  constructor(private db: FirebaseDataService,
              private afs: AngularFirestore) {
  }

  getAll(): Observable<Route[]> {
    return this.db.colWithIds$('routes', ref => ref.where('trash', '==', false))
  }

  get(routeKey: string): Observable<Station> {
    return this.db.docWithId$(`routes/${routeKey}`);
  }

  update(routeKey: string, driver: Station): Promise<void> {
    return this.afs.doc(`routes/${routeKey}`).update({
      ...driver,
      updatedAt: new Date().getTime()
    });
  }

  delete(routeKey: string): Promise<void> {
    return this.afs.doc(`routes/${routeKey}`).update({
      trash: true,
      updatedAt: new Date().getTime()
    });
  }

  set(routeKey: string, route: Route) {
    return this.afs.doc(`routes/${routeKey}`).set(route, {merge: true});
  }

  getRef(routeKey: string) {
    return this.afs.doc(`routes/${routeKey}`).ref;
  }

  getSettings() {
    return this.db.docWithId$(`settings/general`).pipe(take(1)).toPromise();
  }

  addSettings(setting) {
    return this.db.doc(`settings/general`).set(setting);
  }
  
  setSettings(setting) {
    return this.db.doc(`settings/general`).update(setting);
  }
}
