import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {RouteModalComponent} from "../../route-modal.component";
import {BsModalService} from "ngx-bootstrap/modal";
import {SchedulesStationsModalComponent} from "../schedules-stations-modal/schedules-stations-modal.component";
import {ScheduleService} from "../../../../../../services/schedule.service";
import _ from "lodash";
import {AlertService} from "../../../../../../../shared/services/alert.service";

@Component({
  selector: 'app-schedules-stations',
  templateUrl: './schedules-stations.component.html',
  styleUrls: ['./schedules-stations.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SchedulesStationsComponent implements OnInit {

  constructor(public routeModal: RouteModalComponent,
              private modal: BsModalService,
              public _schedule: ScheduleService) {
  }

  ngOnInit(): void {
  }

  addSchedule() {
    const modalRef = this.modal.show(SchedulesStationsModalComponent, {
      initialState: {
        stations: [...this.routeModal.routeForm.value.stations],
        routeKey: this.routeModal.route.key,
        drivers: this.routeModal.drivers,
        vehicles: this.routeModal.vehicles
      },
      id: 'stationsSchedules'
    });
    modalRef.content.scheduleEmitter.subscribe(res => {
      if (!res) return;
      this.routeModal.schedules = [
        ...this.routeModal.schedules,
        res
      ]
    });

  }

  editSchedule(index: number) {
    const modalRef = this.modal.show(SchedulesStationsModalComponent, {
      initialState: {
        stations: [...this.routeModal.routeForm.value.stations],
        routeKey: this.routeModal.route.key,
        isEdit: true,
        schedules: _.cloneDeep(this.routeModal.schedules[index]),
        drivers: this.routeModal.drivers,
        vehicles: this.routeModal.vehicles
      },
      id: 'stationsSchedules'
    })

    modalRef.content.scheduleEmitter.subscribe(res => {
      if (!res) return;

      this.routeModal.schedules[index] = res;
    });
  }

  async deleteSchedule(schedule: any, index: number) {
    if (!(await AlertService.confirm('¿Estás seguro de que deseas eliminar este horario?'))) return;

    if (!!schedule.key) await this._schedule.delete(this.routeModal.route.key, schedule.key);

    this.routeModal.schedules.splice(index, 1);
  }
}
