<div id="map-preview"
     class="content">
  <google-map [options]="options"
              width="100%"
              #map
              *ngIf="visibleMap">
    <map-marker #marker="mapMarker"
                *ngFor="let station of stations"
                [position]="station"
                [options]="getMarkerOptions(station)"
                (mapClick)="openInfoWindow(marker, station)"></map-marker>

    <ng-container *ngFor="let directions$ of directionsResults$; last as last">
      <map-directions-renderer *ngIf="(directions$ | async) as directionsResults"
                               [directions]="directionsResults"
                               [options]="last && route.isCircuit ? lastDirectionsRenderOptions : directionsRenderOptions"></map-directions-renderer>
    </ng-container>

    <map-info-window>
      <div class="d-flex p-1"
           *ngIf="stationSelected">
        <img [src]="stationSelected?.imageUrl"
             class="station-image">
        <div class="ml-1">
          <h5>{{stationSelected?.name}}</h5>
          <p>{{stationSelected.address}}</p>

        </div>
      </div>
    </map-info-window>
  </google-map>

  <div class="d-flex justify-content-between mt-3">
    <button class="btn btn-outline-secondary btn-prev"
            (click)="routeModal.previousWizard()"
            rippleEffect>
      <i data-feather="arrow-left"
         class="align-middle mr-sm-25 mr-0"></i>
      <span class="align-middle d-sm-inline-block d-none">Anterior</span>
    </button>
    <button [disabled]="routeModal.loading || this.routeModal.routeForm.invalid"
            (click)="routeModal.submit()"
            class="btn btn-primary btn-success btn-submit"
            tabindex="4"
            rippleEffect>
            <span *ngIf="routeModal.loading"
                  class="spinner-border spinner-border-sm mr-1"></span>Guardar
    </button>
  </div>
</div>
