import { CoreMenu } from "../../@core/types";

export const AdminMenu: CoreMenu[] = [
  {
    id: 'users',
    title: 'Usuarios',
    type: 'item',
    icon: 'users',
    url: 'users'
  },
  {
    id: 'drivers',
    title: 'Choferes',
    type: 'item',
    icon: 'user',
    url: 'drivers'
  },
  {
    id: 'vehicles',
    title: 'Vehículos',
    type: 'item',
    icon: 'truck',
    url: 'vehicles'
  },
  {
    id: 'stations',
    title: 'Estaciones',
    type: 'item',
    icon: 'map-pin',
    url: 'stations'
  },
  {
    id: 'routes',
    title: 'Rutas',
    type: 'item',
    icon: 'map',
    url: 'routes'
  },
  {
    id: 'assigns',
    title: 'Asignaciones',
    type: 'item',
    icon: 'repeat',
    url: 'assigns/gantt'
  },
  {
    id: 'welcome-sliders',
    title: 'Sliders',
    type: 'item',
    icon: 'image',
    url: 'welcome-sliders'
  },
  {
    id: 'logs',
    title: 'Bitácora',
    type: 'item',
    icon: 'file-text',
    url: 'logs'
  },
  {
    id: 'map',
    title: 'Mapa',
    type: 'item',
    icon: 'map',
    url: 'map'
  },
];
